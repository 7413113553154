import SignUpForm from "../../components/sign-up-form/sign-up-form.component";
import SignInForm from "../../components/sign-in-form/sign-in-form.component";
import Navigation from '../../routes/navigation/navigation.component';
import "./authentication.styles.scss";

const Authentication = () => {
  return (
    <>
    {/* <Navigation /> */}
    {/* <div className="authentication-container"> */}
      <SignInForm />
      {/* <SignUpForm /> */}
    {/* </div> */}
    </>
  );
};

export default Authentication;

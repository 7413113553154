import Header from './Header';
import SideNav from './SideNav';
import Footer from './Footer';
import React, { useEffect } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
export default function OnlineSalesLog() {


  return (
  <>
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
       <div className="loader-wrapper">
        <div className="loader-box">
            <div className="loader-35"></div>
        </div>
      </div>
      <Header></Header>
      
      <div className="page-body-wrapper sidebar-icon">
        
      <SideNav></SideNav>
        <div className="page-body ecommerce-dash">
          <div className="container-fluid">
            <div className="page-header dash-breadcrumb">
              <div className="row">
                <div className="col-6">                              
                  <h3 className="f-w-600">Online Sales Report</h3>
                </div>
                <div className="col-6">
                  <div className="breadcrumb-sec">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li>
                      <li className="breadcrumb-item">Dashboard</li>
                      <li className="breadcrumb-item active">Online Sales Report</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row size-column">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header pb-0 d-block">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-sm-4">
                            <label className="form-label">From</label>
                            <input className="form-control digits" type="date"/>
                          </div>
                          <div className="col-sm-4">
                            <label className="form-label">To</label>
                            <input className="form-control digits" type="date"/>
                          </div>
                          <div className="col-sm-4">
                            <button className="btn btn-primary m-t-30" type="submit">Apply</button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 text-end">
                        <div className="btn-group">
                          <button className="btn btn-primary" title="Copy"><i className="fa-solid fa-copy"></i></button>
                          <button className="btn btn-secondary" title="Excel"><i className="fa-solid fa-file-spreadsheet"></i></button>
                          <button className="btn btn-success" title="PDF"><i className="fa-solid fa-file-pdf"></i></button>
                          <div className="btn-group" role="group">
                            <button className="btn btn-light dropdown-toggle" id="btnGroupDrop1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Filter</button>
                            <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                              <a className="dropdown-item" href="#">Online Sales</a>
                              <a className="dropdown-item" href="#">Daily Sales</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered dt-responsive mb-5">
												<thead>
													<tr >
														<th>Filter between</th>
														<th>Total Orders</th>
														<th>Total items</th>
														<th>Total Payouts </th>
													</tr>
												</thead>
												<tbody>
                            <tr >
														<td>2023-02-15 - 2023-02-22</td>
														<td>20</td>
														<td>49</td>
														<td>514.72 €</td>
													</tr>
                        </tbody>
											</table>
                      <table id="example" className="table table-striped dt-responsive nowrap table-hover">
                        <thead>
                            <tr>
                                <th>Si No</th>
                                <th>Date And Time</th>
                                <th>Order ID</th>
                                <th>Customer Name</th>
                                <th>Items</th>
                                <th>Amount</th>
                                <th>Payment Fee €</th>
                                <th>Payment Amt €</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td>1</td>
                              <td>2023-02-15 - 12:30</td>
                              <td><a href="">RES8098111676457040</a></td>
                              <td><a href="">Olli</a></td>
                              <td>2</td>
                              <td>22.60 €</td>
                              <td>2.84 €</td>
                              <td>19.76 €</td>
                              <td><p className="badge badge-success">Success</p></td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>2023-02-15 - 12:30</td>
                              <td><a href="">RES750111676461184</a></td>
                              <td><a href="">Tero Niemi</a></td>
                              <td>1</td>
                              <td>10.90 €</td>
                              <td>1.67 €</td>
                              <td>9.23 €</td>
                              <td><p className="badge badge-success">Success</p></td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>2023-02-15 - 15:42</td>
                              <td><a href="">RES8859111676468523</a></td>
                              <td><a href="">Aksel</a></td>
                              <td>2</td>
                              <td>17.50 €</td>
                              <td>2.33 €</td>
                              <td>15.17 €</td>
                              <td><p className="badge badge-success">Success</p></td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>2023-02-15 - 17:44</td>
                              <td><a href="">RES1601111676475882</a></td>
                              <td><a href="">Vilppu Nieminen</a></td>
                              <td>5</td>
                              <td>65.10 €</td>
                              <td>7.09 €</td>
                              <td>58.01 €</td>
                              <td><p className="badge badge-success">Success</p></td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>2023-02-16 - 13:39</td>
                              <td><a href="">RES4119111676547568</a></td>
                              <td><a href="">Janita</a></td>
                              <td>1</td>
                              <td>10.90 €</td>
                              <td>1.67 €</td>
                              <td>9.23 €</td>
                              <td><p className="badge badge-success">Success</p></td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>2023-02-16 - 14:13</td>
                              <td><a href="">RES5233111676549619</a></td>
                              <td><a href="">Arja-Riikka Nikkilä</a></td>
                              <td>2</td>
                              <td>22.20 €</td>
                              <td>2.80 €</td>
                              <td>19.40 €</td>
                              <td><p className="badge badge-success">Success</p></td>
                            </tr>
                            <tr>
                              <td>7</td>
                              <td>2023-02-17 - 10:56</td>
                              <td><a href="">RES8887111676624201</a></td>
                              <td><a href="">Sara Sällsten</a></td>
                              <td>3</td>
                              <td>36.10 €</td>
                              <td>4.19 €</td>
                              <td>31.91 €</td>
                              <td><p className="badge badge-success">Success</p></td>
                            </tr>
                            <tr>
                              <td>8</td>
                              <td>2023-02-17 - 11:36</td>
                              <td><a href="">RES5454111676626563</a></td>
                              <td><a href="">Aleksi Tarvainen</a></td>
                              <td>1</td>
                              <td>10.90 €</td>
                              <td>1.67 €</td>
                              <td>9.23 €</td>
                              <td><p className="badge badge-success">Success</p></td>
                            </tr>
                            <tr>
                              <td>9</td>
                              <td>2023-02-17 - 13:45</td>
                              <td><a href="">RES787111676634330</a></td>
                              <td><a href="">Miko?aj</a></td>
                              <td>3</td>
                              <td>27.30 €</td>
                              <td>3.31 €</td>
                              <td>23.99 €</td>
                              <td><p className="badge badge-success">Success</p></td>
                            </tr>
                            <tr>
                              <td>10</td>
                              <td>2023-02-17 - 15:57</td>
                              <td><a href="">RES8903111676642227</a></td>
                              <td><a href="">Inka Laaksonen</a></td>
                              <td>4</td>
                              <td>47.70 €</td>
                              <td>3.31 €</td>
                              <td>42.35 €</td>
                              <td><p className="badge badge-success">Success</p></td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div id="right-sidebar" className="settings-panel">
            <i className="settings-close fa-regular fa-circle-xmark f-20"></i>
            
          </div>

          

        </div>
        <div className="tap-top"><i className="fa-solid fa-arrow-turn-up"></i></div>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 footer-copyright">
                <p className="mb-0">Copyright 2023 © Softa All rights reserved.</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
    <div className="modal fade" id="OrderDetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title">ORDER DETAILS</h6>
            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="table-responsive mb-3">
              <table className="table table-striped table-bordernone">
                <tbody>
                  <tr>
                    <td><strong>Order ID : RES3608111655576293</strong></td>
                    <td><strong>Customer Name : Siiri Westerberg</strong></td>
                  </tr>
                  <tr>                    
                    <td><strong>Contact No : 413196233</strong></td>
                    <td><strong>Date & Time : 2022-06-18, 21:18</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="text-danger mb-3"><strong>Note:- </strong></p>
            <div className="table-responsive">
              <table className="table table-bordered table-striped">
                  <thead>
                      <tr>
                          <th className="font">#</th>
                          <th className="font">Category</th>
                          <th className="font">Product</th>
                          <th className="font">Price</th>
                          <th className="font">Quantity</th>
                          <th className="font">Total</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>VEGETARIAN DISHES	</td>
                      <td>MALAI KOFTA</td>
                      <td>13.90€</td>
                      <td>1</td>
                      <td>13.90€</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>CHICKEN DISHES	</td>
                      <td>BUTTER CHICKEN</td>
                      <td>15.90€</td>
                      <td>1</td>
                      <td>15.90€</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>SIDE ORDER</td>
                      <td>RAITA</td>
                      <td>2.50€</td>
                      <td>1</td>
                      <td>2.50€</td>
                    </tr>
                    <tr>
                      <td colspan="5" className="total-amount p-2">
                        <p className="m-0"><span className="f-w-600">In Total :</span></p>
                      </td>
                      <td className="p-2"><span>32.30 €</span></td>
                    </tr>
                    <tr>
                      <td colspan="5" className="total-amount p-2">
                        <p className="m-0"><span className="f-w-600">Delivery Charges :</span></p>
                      </td>
                      <td className="p-2"><span>+0.00 €</span></td>
                    </tr>
                    <tr>
                      <td colspan="5" className="total-amount p-2">
                        <p className="m-0"><span className="f-w-600">Discount :</span></p>
                      </td>
                      <td className="p-2"><span>0%</span></td>
                    </tr>
                    <tr>
                      <td colspan="5" className="total-amount p-2">
                        <p className="m-0"><span className="f-w-600">Redeemed Gift Card :</span></p>
                      </td>
                      <td className="p-2"><span>-0.00 €</span></td>
                    </tr>
                    <tr>
                      <td colspan="5" className="total-amount p-2">
                        <p className="m-0"><span className="f-w-600">Payable Amount <small>All (14% included): 4.52 €</small> :</span></p>
                      </td>
                      <td className="p-2"><span>32.30 €</span></td>
                    </tr>
                  </tbody>
              </table>
          </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" type="button" data-bs-dismiss="modal">Cancel</button>
            <button className="btn btn-primary" type="button">Submit</button>
          </div>
        </div>
      </div>
    </div>
    </>
    )
  }

const TemplateCard = (props) => (
  
  <div className="col-lg-9">
  <div className="port portfolio-masonry">
    <div className="portfolioContainer row action">
    {props.filteredTemplates.length > 0 ? props.filteredTemplates.map((product) => {
  return (
      <div key={product.id}  className="col-lg-6 ">
        <div className="item-box">
          <a className="mfp-image" 
        onClick={() => props.editTemplateById(product.main_id)} title="Project Name">
            <img className="item-container img-fluid" src={product.template_image}
        alt={`${product.label}`} />
            <div className="item-mask">
              <div className="item-caption">
        
                <h6 className="text-white mt-1 text-uppercase">EDIT</h6>
                <p className="text-white mb-0">{product.label}</p>
              </div>
            </div>
          </a>
          
        </div>
        <div className="footer">
        <span className="name">{product.label}</span>
       
      </div>
      </div> );
       }): '' }

    </div>
  </div>
</div>
);

export default TemplateCard;

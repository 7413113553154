import Header from './Header';
import SideNav from './SideNav';
import Footer from './Footer';
import React, { useEffect } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
export default function CouponCode() {


  return (
  <>
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
       <div className="loader-wrapper">
        <div className="loader-box">
            <div className="loader-35"></div>
        </div>
      </div>
      <Header></Header>
      
      <div className="page-body-wrapper sidebar-icon">
        
      <SideNav></SideNav>
        <div class="page-body ecommerce-dash">
          <div class="container-fluid">
            <div class="page-header dash-breadcrumb">
              <div class="row">
                <div class="col-6">                              
                  <h3 class="f-w-600">Manage Coupon</h3>
                </div>
                <div class="col-6">
                  <div class="breadcrumb-sec">
                    <ul class="breadcrumb">
                      <li class="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li>
                      <li class="breadcrumb-item">Dashboard</li>
                      <li class="breadcrumb-item active">Manage Coupon</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid">
            <div class="row size-column">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header pb-0 d-block">
                    <div class="row">
                      <div class="col-md-6">
                        
                      </div>
                      <div class="col-md-6 text-end">
                        <button class="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#exampleModalCenter"> Create Coupon </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table id="example" class="table table-striped dt-responsive nowrap table-hover" >
                        <thead>
                            <tr>
                                <th>Si No</th>
                                <th>Coupon Name</th>
                                <th>Coupon type</th>
                                <th>Coupon flat</th>
                                <th>Percentage</th>
                                <th>Start Date</th>
                                <th>End date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div id="right-sidebar" class="settings-panel">
            <i class="settings-close fa-regular fa-circle-xmark f-20"></i>
            
          </div>

          

        </div>
        <div class="tap-top"><i class="fa-solid fa-arrow-turn-up"></i></div>
        <footer class="footer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 footer-copyright">
                <p class="mb-0">Copyright 2023 © Softa All rights reserved.</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-hidden="true">
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Add Gallery Image</h6>
            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3 row">
              <div class="col-lg-6">
                <label class="form-label">Coupon name:</label>
                <input class="form-control" type="text" placeholder="Please Enter Coupon name"/>
              </div>
              <div class="col-lg-6">
                <label class="form-label">Coupon Type</label>
                <select class="form-select">
                    <option value="">Not Applied</option>
                    <option value="1">Percentage</option>
                    <option value="2">Flat Amount</option>
                </select>
            </div>
            </div>
            <div class="mb-3 row">
              <div class="col-lg-6">
                <label class="form-label">Coupon Flat :</label>
                <input class="form-control" type="text" placeholder="Enter amount like 1 or 1.5"/>
              </div>
              <div class="col-lg-6">
                <label class="form-label">Coupon percentage :</label>
                <input class="form-control" type="text" placeholder="Enter only number like 12 or 12.5"/>
              </div>
            </div>
            <div class="mb-3 row">
              <div class="col-lg-6">
                <label class="form-label">Start date :</label>
                <input class="form-control" type="date"/>
              </div>
              <div class="col-lg-6">
                <label class="form-label">End date :</label>
                <input class="form-control" type="date"/>
              </div>
            </div>
            <div class="mb-3 row">
              <div class="col-lg-6">
                <label class="form-label">Apply coupon for: </label>
                <select required="" class="form-select mb-3">
                  <option value=""> Select category </option>
                  <option value="52">Category</option>
                  <option value="53">Main Menu</option>
                </select>
            </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
            <button class="btn btn-primary" type="button">Add Image</button>
          </div>
        </div>
      </div>
    </div>
    </>
    )
  }
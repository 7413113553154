import React, { useState, useReducer } from "react";
import { useDispatch } from "react-redux";
import { createPage } from "../../redux/actions/pageAction";

export default function PageSection({ pages }) {
  const [show, setShow] = useReducer((show) => !show, false);
  const [name, setName] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (!name) {
      setIsValid(false);
      return;
    } else {
      createPage(name)(dispatch);
      closeModal();
    }
  };

  const closeModal = () => {
    setName("");
    setError("");
    setShow();
  };
  return (
<div></div>
  );
}

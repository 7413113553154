import Header from './Header';
import SideNav from './SideNav';
import Footer from './Footer';
import React, { useEffect } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
export default function LunchTiming() {

  return (

<div className="page-wrapper compact-wrapper" id="pageWrapper">
       <div className="loader-wrapper">
        <div className="loader-box">
            <div className="loader-35"></div>
        </div>
      </div>
      <Header></Header>
      
      <div className="page-body-wrapper sidebar-icon">
        
      <SideNav></SideNav>
      
      
        <div className="page-body ecommerce-dash">
          <div className="container-fluid">
            <div className="page-header dash-breadcrumb">
              <div className="row">
                <div className="col-6">                              
                  <h3 className="f-w-600">Lunch Timings</h3>
                </div>
                <div className="col-6">
                  <div className="breadcrumb-sec">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li>
                      <li className="breadcrumb-item">Dashboard</li>
                      <li className="breadcrumb-item active">Lunch Timings</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row size-column">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header pb-0 d-block">
                    
                  </div>
                  <div className="card-body">
                    <div className="table-responsive mb-3">
                      <table id="example" className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Sr. No</th>
                                <th>Day</th>
                                <th>Open Time</th>
                                <th>Close Time</th>
                                <th>Closed Day</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Monday</td>
                                <td><input className="form-control" type="text" placeholder="Open Time"/></td>
                                <td><input className="form-control" type="text"placeholder="Close Time"/></td>
                                <td>
                                  <label className="d-block" for="chk-ani">
                                    <input className="checkbox_animated" id="chk-ani" type="checkbox"/> Close
                                  </label>
                                </td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-sharp fa-solid fa-circle-check"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Tuesday</td>
                                <td><input className="form-control" type="text" placeholder="Open Time"/></td>
                                <td><input className="form-control" type="text"placeholder="Close Time"/></td>
                                <td>
                                  <label className="d-block" for="chk-ani1">
                                    <input className="checkbox_animated" id="chk-ani1" type="checkbox"/> Close
                                  </label>
                                </td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-sharp fa-solid fa-circle-check"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Wednesday</td>
                                <td><input className="form-control" type="text" placeholder="Open Time"/></td>
                                <td><input className="form-control" type="text"placeholder="Close Time"/></td>
                                <td>
                                  <label className="d-block" for="chk-ani2">
                                    <input className="checkbox_animated" id="chk-ani2" type="checkbox"/> Close
                                  </label>
                                </td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-sharp fa-solid fa-circle-check"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Thursday</td>
                                <td><input className="form-control" type="text" placeholder="Open Time"/></td>
                                <td><input className="form-control" type="text"placeholder="Close Time"/></td>
                                <td>
                                  <label className="d-block" for="chk-ani3">
                                    <input className="checkbox_animated" id="chk-ani3" type="checkbox"/> Close
                                  </label>
                                </td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-sharp fa-solid fa-circle-check"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Friday</td>
                                <td><input className="form-control" type="text" placeholder="Open Time"/></td>
                                <td><input className="form-control" type="text"placeholder="Close Time"/></td>
                                <td>
                                  <label className="d-block" for="chk-ani3">
                                    <input className="checkbox_animated" id="chk-ani4" type="checkbox"/> Close
                                  </label>
                                </td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-sharp fa-solid fa-circle-check"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Saturday</td>
                                <td><input className="form-control" type="text" placeholder="Open Time"/></td>
                                <td><input className="form-control" type="text"placeholder="Close Time"/></td>
                                <td>
                                  <label className="d-block" for="chk-ani3">
                                    <input className="checkbox_animated" id="chk-ani5" type="checkbox"/> Close
                                  </label>
                                </td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-sharp fa-solid fa-circle-check"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Sunday</td>
                                <td><input className="form-control" type="text" placeholder="Open Time"/></td>
                                <td><input className="form-control" type="text" placeholder="Close Time"/></td>
                                <td>
                                  <label className="d-block" for="chk-ani3">
                                    <input className="checkbox_animated" id="chk-ani6" type="checkbox"/> Close
                                  </label>
                                </td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-sharp fa-solid fa-circle-check"></i></button>
                                </td>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <button className="btn btn-info" type="button"><i className="fa-sharp fa-solid fa-circle-check"></i> Update All</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div id="right-sidebar" className="settings-panel">
            <i className="settings-close fa-regular fa-circle-xmark f-20"></i>
            
          </div>

          

        </div>
        <div className="tap-top"><i className="fa-solid fa-arrow-turn-up"></i></div>
     
      </div>
    </div>

    )
}

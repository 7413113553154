
import Header from './Header';
import SideNav from './SideNav';
import Footer from './Footer';
import React, { useEffect } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
export default function OrderHistory() {


  return (
  <>
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
       <div className="loader-wrapper">
        <div className="loader-box">
            <div className="loader-35"></div>
        </div>
      </div>
      <Header></Header>
      
      <div className="page-body-wrapper sidebar-icon">
        
      <SideNav></SideNav>
        <div className="page-body ecommerce-dash">
          <div className="container-fluid">
            <div className="page-header dash-breadcrumb">
              <div className="row">
                <div className="col-4">                              
                  <h3 className="f-w-600">Orders List</h3>
                </div>
                <div className="col-md-4 text-center">
                  <div className="btn-group">
                    <button className="btn btn-primary" title="Copy"><i className="fa-solid fa-copy"></i></button>
                    <button className="btn btn-secondary" title="Excel"><i className="fa-solid fa-file-spreadsheet"></i></button>
                    <button className="btn btn-success" title="PDF"><i className="fa-solid fa-file-pdf"></i></button>
                    <div className="btn-group" role="group">
                      <button className="btn btn-light dropdown-toggle" id="btnGroupDrop1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Filter</button>
                      <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                        <a className="dropdown-item" href="#">Accepted Orders</a>
                        <a className="dropdown-item" href="#">Cancelled Orders</a>
                        <a className="dropdown-item" href="#">Failed Orders</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="breadcrumb-sec">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li>
                      <li className="breadcrumb-item">Dashboard</li>
                      <li className="breadcrumb-item active">Orders List</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row size-column">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header pb-0 d-block">
                    
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table id="example" className="table table-striped dt-responsive nowrap table-hover">
                        <thead>
                            <tr>
                                <th>Si No</th>
                                <th>Order ID</th>
                                <th>User Name</th>
                                <th>Email</th>
                                <th>Contact No</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>RES3608111655576293</td>
                                <td>Siiri Westerberg	</td>
                                <td></td>
                                <td>413196233</td>
                                <td>2022-06-18</td>
                                <td>21:18</td>
                                <td><p className="badge badge-warning">Pending</p></td>
                                <td>
                                  <button className="btn btn-pill btn-outline-primary btn-xs" type="button" title="Accept Order"><i className="fa-solid fa-circle-check"></i></button>    
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View Order" data-bs-toggle="modal" data-bs-target="#OrderDetails"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Cancel Order" data-bs-toggle="modal" data-bs-target="#OrderDetails"><i className="fa-regular fa-circle-xmark"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>RES3829111657129661</td>
                                <td>Petra L</td>
                                <td></td>
                                <td>445001194</td>
                                <td>2022-07-06</td>
                                <td>20:47</td>
                                <td><p className="badge badge-warning">Pending</p></td>
                                <td>
                                  <button className="btn btn-pill btn-outline-primary btn-xs" type="button" title="Accept Order"><i className="fa-solid fa-circle-check"></i></button>                                  
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View Order" data-bs-toggle="modal" data-bs-target="#OrderDetails"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Cancel Order"><i className="fa-regular fa-circle-xmark"></i></button>
                                </td>
                            </tr>
                            
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div id="right-sidebar" className="settings-panel">
            <i className="settings-close fa-regular fa-circle-xmark f-20"></i>
            
          </div>

          

        </div>
        <div className="tap-top"><i className="fa-solid fa-arrow-turn-up"></i></div>
      </div>
    </div>
    <div className="modal fade" id="OrderDetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title">ORDER DETAILS</h6>
            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="table-responsive mb-3">
              <table className="table table-striped table-bordernone">
                <tbody>
                  <tr>
                    <td><strong>Order ID : RES3608111655576293</strong></td>
                    <td><strong>Customer Name : Siiri Westerberg</strong></td>
                  </tr>
                  <tr>                    
                    <td><strong>Contact No : 413196233</strong></td>
                    <td><strong>Date & Time : 2022-06-18, 21:18</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="text-danger mb-3"><strong>Note:- </strong></p>
            <div className="table-responsive">
              <table className="table table-bordered table-striped">
                  <thead>
                      <tr>
                          <th className="font">#</th>
                          <th className="font">Category</th>
                          <th className="font">Product</th>
                          <th className="font">Price</th>
                          <th className="font">Quantity</th>
                          <th className="font">Total</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>VEGETARIAN DISHES	</td>
                      <td>MALAI KOFTA</td>
                      <td>13.90€</td>
                      <td>1</td>
                      <td>13.90€</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>CHICKEN DISHES	</td>
                      <td>BUTTER CHICKEN</td>
                      <td>15.90€</td>
                      <td>1</td>
                      <td>15.90€</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>SIDE ORDER</td>
                      <td>RAITA</td>
                      <td>2.50€</td>
                      <td>1</td>
                      <td>2.50€</td>
                    </tr>
                    <tr>
                      <td colspan="5" className="total-amount p-2">
                        <p className="m-0"><span className="f-w-600">In Total :</span></p>
                      </td>
                      <td className="p-2"><span>32.30 €</span></td>
                    </tr>
                    <tr>
                      <td colspan="5" className="total-amount p-2">
                        <p className="m-0"><span className="f-w-600">Delivery Charges :</span></p>
                      </td>
                      <td className="p-2"><span>+0.00 €</span></td>
                    </tr>
                    <tr>
                      <td colspan="5" className="total-amount p-2">
                        <p className="m-0"><span className="f-w-600">Discount :</span></p>
                      </td>
                      <td className="p-2"><span>0%</span></td>
                    </tr>
                    <tr>
                      <td colspan="5" className="total-amount p-2">
                        <p className="m-0"><span className="f-w-600">Redeemed Gift Card :</span></p>
                      </td>
                      <td className="p-2"><span>-0.00 €</span></td>
                    </tr>
                    <tr>
                      <td colspan="5" className="total-amount p-2">
                        <p className="m-0"><span className="f-w-600">Payable Amount <small>All (14% included): 4.52 €</small> :</span></p>
                      </td>
                      <td className="p-2"><span>32.30 €</span></td>
                    </tr>
                  </tbody>
              </table>
          </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" type="button" data-bs-dismiss="modal">Cancel</button>
            <button className="btn btn-primary" type="button">Submit</button>
          </div>
        </div>
      </div>
    </div>
    </>
    )
  }
import React,{ useState,useEffect } from 'react';
import { useHistory  } from "react-router-dom";
import { Link } from 'react-router-dom';
import FormInput from '../../components/form-input/form-input.component';
import Button from '../../components/button/button.component';
import { Router } from 'react-router';
import {
  signInAuthUserWithEmailAndPassword,
  signInWithGooglePopup,
} from '../../utils/firebase/firebase.utils';
import { API_HOST } from "../../api_utils";
import axios from "axios";
import $ from 'jquery';
import jQuery from 'jquery';
const defaultFormFields = {
    email: '',
    password: '',
  };
function Login() {
  const history  = useHistory();
    useEffect(() => {
        // $(document).ready(function() {
        //     $('#example').DataTable( {
        //         select: true
        //     } );
        // } );
        // $(document).ready(function() {
        //     $('#example1').DataTable( {
        //         select: true
        //     } );
        // } );
        $(".nav-settings").on("click", function() {
          $("#right-sidebar").toggleClass("open");
        });
        $(".settings-close").on("click", function() {
          $("#right-sidebar,#theme-settings").removeClass("open");
        });
        $(".toggle-nav").click(function () {
          $('.nav-menu').css("left", "0px");
        });
        $(".mobile-back").click(function () {
            $('.nav-menu').css("left", "-410px");
        });
    
        $(".page-wrapper").attr("class", "page-wrapper "+localStorage.getItem('page-wrapper'));
        $(".page-body-wrapper").attr("class", "page-body-wrapper "+localStorage.getItem('page-body-wrapper'));
    
        if (localStorage.getItem("page-wrapper") === null) {
            $(".page-wrapper").addClass("compact-wrapper");
            $(".page-body-wrapper").addClass("sidebar-icon");
        }
    
        if($('#pageWrapper').hasClass('compact-wrapper')){
    
          jQuery('.menu-title').append('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
            jQuery('.menu-title').click(function () {
                jQuery('.menu-title').removeClass('active').find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
                jQuery('.menu-content').slideUp('normal');
                if (jQuery(this).next().is(':hidden') == true) {
                    jQuery(this).addClass('active');
                    jQuery(this).find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-down"></i></div>');
                    jQuery(this).next().slideDown('normal');
                } else {
                    jQuery(this).find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
                }
            });
            jQuery('.menu-content').hide();
    
            
            jQuery('.submenu-title').append('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
            jQuery('.submenu-title').click(function () {
                jQuery('.submenu-title').removeClass('active').find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
                jQuery('.submenu-content').slideUp('normal');
                if (jQuery(this).next().is(':hidden') == true) {
                    jQuery(this).addClass('active');
                    jQuery(this).find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-down"></i></div>');
                    jQuery(this).next().slideDown('normal');
                } else {
                    jQuery(this).find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
                }
            });
            jQuery('.submenu-content').hide();
    
      } else if ($('#pageWrapper').hasClass('horizontal-wrapper')) {
          var contentwidth = jQuery(window).width();
          if ((contentwidth) < '992') {
              $('#pageWrapper').removeClass('horizontal-wrapper').addClass('compact-wrapper');
              $('.page-body-wrapper').removeClass('horizontal-menu').addClass('sidebar-icon');
              jQuery('.submenu-title').append('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
            jQuery('.submenu-title').click(function () {
                jQuery('.submenu-title').removeClass('active');
                jQuery('.submenu-title').find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
                jQuery('.submenu-content').slideUp('normal');
                if (jQuery(this).next().is(':hidden') == true) {
                    jQuery(this).addClass('active');
                    jQuery(this).find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-down"></i></div>');
                    jQuery(this).next().slideDown('normal');
                } else {
                    jQuery(this).find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
                }
            });
            jQuery('.submenu-content').hide();
    
            jQuery('.menu-title').append('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
            jQuery('.menu-title').click(function () {
                jQuery('.menu-title').removeClass('active');
                jQuery('.menu-title').find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
                jQuery('.menu-content').slideUp('normal');
                if (jQuery(this).next().is(':hidden') == true) {
                    jQuery(this).addClass('active');
                    jQuery(this).find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-down"></i></div>');
                    jQuery(this).next().slideDown('normal');
                } else {
                    jQuery(this).find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
                }
            });
            jQuery('.menu-content').hide();
          }
    
      }
      // toggle sidebar
      let $nav =""; let $header=""; let $toggle_nav_top =""; let $this ="";
      $nav = $('.main-nav');
      $header = $('.page-main-header');
      $toggle_nav_top = $('#sidebar-toggle');
      $toggle_nav_top.click(function() {
        $this = $(this);
        $nav = $('.main-nav');
        $nav.toggleClass('close_icon');
        $header.toggleClass('close_icon');
      });
    
      $( window ).resize(function() {
        $nav = $('.main-nav');
        $header = $('.page-main-header');
        $toggle_nav_top = $('#sidebar-toggle');
        $toggle_nav_top.click(function() {
          $this = $(this);
          $nav = $('.main-nav');
          $nav.toggleClass('close_icon');
          $header.toggleClass('close_icon');
        });
      });
      
      let $body_part_side ="";
      $body_part_side = $('.body-part');
      $body_part_side.click(function(){
        $toggle_nav_top.attr('checked', false);
        $nav.addClass('close_icon');
        $header.addClass('close_icon');
      });
      
      //    responsive sidebar
      var $window = $(window);
      var widthwindow = $window.width();
      (function($) {
      "use strict";
      if(widthwindow <= 991) {
          $toggle_nav_top.attr('checked', false);
          $nav.addClass("close_icon");
          $header.addClass("close_icon");
      }
      })(jQuery);
      $( window ).resize(function() {
      var widthwindaw = $window.width();
      if(widthwindaw <= 991){
          $toggle_nav_top.attr('checked', false);
          $nav.addClass("close_icon");
          $header.addClass("close_icon");
      }else{
          $toggle_nav_top.attr('checked', true);
          $nav.removeClass("close_icon");
          $header.removeClass("close_icon");
      }
      });
      
    // horizontal arrowss
    var view = $("#mainnav");
    var move = "500px";
    var leftsideLimit = -500
    
    // var Windowwidth = jQuery(window).width();
    // get wrapper width
    var getMenuWrapperSize = function () {
        return $('.sidebar-wrapper').innerWidth();
    }
    var menuWrapperSize = getMenuWrapperSize();
    
    if ((menuWrapperSize) >= '1660') {
        var sliderLimit = -3000
        
    } else if ((menuWrapperSize) >= '1440') {
        var sliderLimit = -3600
    } else {
        var sliderLimit = -4200
    }
    
    $("#left-arrow").addClass("disabled");
    
    $("#right-arrow").click(function(){
        var currentPosition = parseInt(view.css("left"));
        if (currentPosition >= sliderLimit) {
            $("#left-arrow").removeClass("disabled");
            view.stop(false,true).animate({left:"-="+move},{ duration: 400})
            if (currentPosition == sliderLimit) {
                $(this).addClass("disabled");
            }
        }
    });
    
    $("#left-arrow").click(function(){
        var currentPosition = parseInt(view.css("left"));
        if (currentPosition < 0) {
            view.stop(false,true).animate({left:"+="+move},{ duration: 400})
            $("#right-arrow").removeClass("disabled");
            $("#left-arrow").removeClass("disabled");
            if (currentPosition >= leftsideLimit) {
                $(this).addClass("disabled");
            }
        }
    
    });
    
    // page active
        $( ".main-navbar" ).find( "a" ).removeClass("active");
        $( ".main-navbar" ).find( "li" ).removeClass("active");
    
        var current = window.location.pathname
        $(".main-navbar ul>li a").filter(function() {
    
            var link = $(this).attr("href");
            if(link){
                if (current.indexOf(link) != -1) {
                    $(this).parents().children('a').addClass('active');
                    $(this).parents().parents().children('ul').css('display', 'block');
                    $(this).addClass('active');
                    $(this).parent().parent().parent().children('a').find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-down"></i></div>');
                    $(this).parent().parent().parent().parent().parent().children('a').find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-down"></i></div>');
                    return false;
                }
            }
        });
        (function($) {
          "use strict";
          $(".mobile-toggle").click(function(){
              $(".nav-menus").toggleClass("open");
          });
          $(".mobile-toggle-left").click(function(){
              $(".left-header").toggleClass("open");
          });
          $(".mobile-search").click(function(){
             $(".form-control-plaintext").toggleClass("open");
         });
          $(".bookmark-search").click(function(){
              $(".form-control-search").toggleClass("open");
          })
          $(".filter-toggle").click(function(){
              $(".product-sidebar").toggleClass("open");
          });
          $(".toggle-data").click(function(){
              $(".product-wrapper").toggleClass("sidebaron");
          });
          $(".form-control-search").keyup(function(e){
              if(e.target.value) {
                  $(".page-wrapper.horizontal-wrapper").addClass("offcanvas-bookmark");
              } else {
                  $(".page-wrapper.horizontal-wrapper").removeClass("offcanvas-bookmark");
              }
          });
      
          /*=====================
            02. Background Image js
            ==========================*/
          $(".bg-center").parent().addClass('b-center');
          $(".bg-img-cover").parent().addClass('bg-size');
          $('.bg-img-cover').each(function () {
              var el = $(this),
                  src = el.attr('src'),
                  parent = el.parent();
              parent.css({
                  'background-image': 'url(' + src + ')',
                  'background-size': 'cover',
                  'background-position': 'center',
                  'display': 'block'
              });
              el.hide();
          });
      })(jQuery);
      
      $('.loader-wrapper').fadeOut('slow', function() {
          $(this).remove();
      });
      
      
      $(window).on('scroll', function() {
          if ($(this).scrollTop() > 600) {
              $('.tap-top').fadeIn();
          } else {
              $('.tap-top').fadeOut();
          }
      });
      
      $('.tap-top').click( function() {
          $("html, body").animate({
              scrollTop: 0
          }, 600);
          return false;
      });
      
    $("#viewfullscreen").on("click", function(){
      if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
          document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
      }
    }
    });
      (function($, window, document, undefined) {
          "use strict";
          var $ripple = $(".js-ripple");
          $ripple.on("click.ui.ripple", function(e) {
              var $this = $(this);
              var $offset = $this.parent().offset();
              var $circle = $this.find(".c-ripple__circle");
              var x = e.pageX - $offset.left;
              var y = e.pageY - $offset.top;
              $circle.css({
                  top: y + "px",
                  left: x + "px"
              });
              $this.addClass("is-active");
          });
          $ripple.on(
              "animationend webkitAnimationEnd oanimationend MSAnimationEnd",
              function(e) {
                  $(this).removeClass("is-active");
              });
      })(jQuery, window, document);
      
      
      // active link
      $(".chat-menu-icons .toogle-bar").click(function(){
          $(".chat-menu").toggleClass("show");
      });
      
      $(".nav-settings").on("click", function() {
        $("#right-sidebar").toggleClass("open");
      });
      $(".settings-close").on("click", function() {
        $("#right-sidebar,#theme-settings").removeClass("open");
      });
      
      //landing header //
      $(".toggle-menu").click(function(){
          $('.landing-menu').toggleClass('open');
      });   
      $(".menu-back").click(function(){
          $('.landing-menu').toggleClass('open');
      });  
      
      
      $('.product-size ul li ').on('click', function(e) {
          $(".product-size ul li ").removeClass("active");
          $(this).addClass("active");
      });
      
      $(".md-sidebar-toggle").click(function(){
          $('.md-sidebar-aside').toggleClass('open');
      });
      
      $('.media-size-email svg').on('click', function (e) {
          $(this).toggleClass("like");
      });
    
      });
      const [formFields, setFormFields] = useState(defaultFormFields);
      const [isLoading, setIsLoading] = useState(false);
      const { email, password } = formFields;
    
      const resetFormFields = () => {
        setFormFields(defaultFormFields);
      };
    
      const signInWithGoogle = async () => {
        await signInWithGooglePopup();
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(event);
        var data = JSON.stringify({
          "user_name": "Sanit Kale",
          "user_profile_img": "",
          "user_profession": "",
          "user_about": "",
          "user_skills": "",
          "user_dob": "1970-09-12",
          "user_address": "",
          "user_email": email,
          "user_phone": "",
          "user_website": "",
          "user_category_id": "",
          "user_register_id": "zktorZwB461dSLRCKcWV",
          "user_profile_background": "",
          "user_password": password,
          "user_logined_method": "email",
          "app_first_fill": "1",
          "app_auth": email,
          "user_profile_name": "jk",
          "user_channel_id": "zktorbpmLIDjKTxZi47I",
          "user_login_history_id": "613e13fad9fd38f0",
          "user_gender": "Male",
          "user_age": "41",
          "caller_id": "=OGCj(6&(5A%PDhBGgQ2)|zTk"
        });
        var config = {
          method: 'post',
        maxBodyLength: Infinity,
          url: `${API_HOST}profile_insert`,
          headers: { 
            'Authorization': '', 
            'Content-Type': 'application/json'
          },
          data : data
        };
        axios(config)
        .then(function (response) {
           history.push('/dashboard');
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
      };
      // const handleGitHubLogin = async () => {
      //  // setIsLoading(true);
      //   try {
      //     const response = await fetch(`https://github.com/login/oauth/authorize?client_id=15f9cc86c96d14536717&scope=user`);
      //     const data = await response.json();
      //     // Do something with the data, such as save the access token
      //   } catch (error) {
      //     console.error(error);
      //   }
      //  // setIsLoading(false);
      // };
      function handleGitHubLogin() {
        // Generate a random state parameter
        const state = Math.random().toString(36).substr(2);
      
        // Save the state parameter to localStorage
        localStorage.setItem('githubState', state);
      
        // Construct the GitHub OAuth URL
        const url = `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&scope=user&state=${state}`;
      
        // Open the GitHub login page in a popup
        const width = 600;
        const height = 600;
        const left = window.innerWidth / 2 - width / 2;
        const top = window.innerHeight / 2 - height / 2;
        const features = `width=${width},height=${height},left=${left},top=${top},menubar=no,toolbar=no`;
        const popup = window.open(url, 'GitHub Login', features);
      
        // Listen for messages from the popup window
        window.addEventListener('message', event => {
          if (event.origin === window.location.origin && event.source === popup) {
            // Verify that the state parameter matches the one we saved
            if (event.data.type === 'github:login' && event.data.state === state) {
              // Save the access token to localStorage or a state variable
              localStorage.setItem('githubAccessToken', event.data.access_token);
      
              // Close the popup window
              popup.close();
      
              // Redirect the user to the home page
              window.location.href = '/';
            } else if (event.data.type === 'github:error') {
              console.error('GitHub login error:', event.data.error_description);
            }
          }
        });
      }
    
      function handleMicrosoftLogin ()  {
        const clientId="ef6d8c0c-4968-4c20-866e-ac765ee9ea15";
        const redirectUri="https://medium.com/zestgeek/login-with-github-and-microsoft-in-reactjs-e33ffbcd933";
        const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=token&redirect_uri=${redirectUri}&scope=user.read`;
    
      
        const width = 600;
        const height = 600;
        const left = window.innerWidth / 2 - width / 2;
        const top = window.innerHeight / 2 - height / 2;
        const features = `width=${width},height=${height},left=${left},top=${top},menubar=no,toolbar=no`;
        const popup = window.open(authUrl,  'Microsoft Login', features);
      
        const handlePopupMessage = (event) => {
          if (event.origin !== window.location.origin){
            console.log('test');
            return;
          } 
          if (event.source !== popup) return;
    
          const authResponse = JSON.parse(event.data);
          console.log(authResponse);
          // Save the access token to localStorage or a state variable
          window.removeEventListener('message', handlePopupMessage);
          popup.close();
        };
    
        window.addEventListener('message', handlePopupMessage);
      
      }
      function handleAppleLogin  () {
        const redirectUri = encodeURIComponent(`${window.location.origin}/apple-redirect`);
        const state = encodeURIComponent(Math.random().toString(36).substring(2, 15));
        const nonce = encodeURIComponent(Math.random().toString(36).substring(2, 15));
    
        const authUrl = `https://appleid.apple.com/auth/authorize?client_id=${process.env.REACT_APP_APPLE_CLIENT_ID}&redirect_uri=${redirectUri}&state=${state}&response_type=code%20id_token&response_mode=form_post&scope=email%20name&nonce=${nonce}`;
    
        const width = 600;
        const height = 600;
        const left = window.innerWidth / 2 - width / 2;
        const top = window.innerHeight / 2 - height / 2;
    
        const popup = window.open(
          authUrl,
          'Apple Login',
          `width=${width},height=${height},left=${left},top=${top}`
        );
    
        const handlePopupMessage = (event) => {
          if (event.origin !== window.location.origin) return;
          if (event.source !== popup) return;
    
          const authResponse = event.data;
          console.log(authResponse);
          // Save the access token to localStorage or a state variable
          window.removeEventListener('message', handlePopupMessage);
          popup.close();
        };
    
        window.addEventListener('message', handlePopupMessage);
      }
      function handleTwitterLogin() {
        // const handleLogin = () => {
          const redirectUri = encodeURIComponent(window.location.origin);
          const authUrl = `https://api.twitter.com/oauth/authenticate?client_id=${process.env.REACT_APP_TWITTER_CLIENT_ID}&response_type=token&redirect_uri=${redirectUri}`;
      
          const width = 600;
          const height = 600;
          const left = window.innerWidth / 2 - width / 2;
          const top = window.innerHeight / 2 - height / 2;
      
          const popup = window.open(
            authUrl,
            'Twitter Login',
            `width=${width},height=${height},left=${left},top=${top}`
          );
      
          const handlePopupMessage = (event) => {
            if (event.origin !== window.location.origin) {
              return;
            }
      
            try {
              const authResponse = JSON.parse(event.data);
              console.log(authResponse);
              // Save the access token to localStorage or a state variable
              window.removeEventListener('message', handlePopupMessage);
              popup.close();
            } catch (e) {}
          };
      
          window.addEventListener('message', handlePopupMessage);
        };
      const handleFacebookLogin = async () => {
        setIsLoading(true);
        try {
          // Initialize the Facebook SDK
          await window.FB.init({
            appId: '185202659227880',
            cookie: true,
            xfbml: true,
            version: 'v12.0'
          });
    
          // Login the user
          const response = await window.FB.login();
          const { authResponse } = response;
          if (authResponse) {
            // User logged in successfully, fetch their profile data
            const profile = await fetch(`https://graph.facebook.com/v12.0/me?fields=name,email,picture&access_token=${authResponse.accessToken}`);
            const profileData = await profile.json();
            // Do something with the profile data, such as save the access token
          } else {
            console.error('User cancelled login or did not fully authorize.');
          }
        } catch (error) {
          console.error(error);
        }
        setIsLoading(false);
      };
      const handleChange = (event) => {
        const { name, value } = event.target;
    
        setFormFields({ ...formFields, [name]: value });
      };
    //   const saveToCookie = () => {
    //     document.cookie = `${COOKIE_NAME}=${JSON.stringify(formData)}`
    //   }
    //   const readCookie = () => {
    //     const cookies = document.cookie.split(';')
    //     let formCookie= "";
    //     cookies.forEach((cookie) => {
    //       if(cookie.startsWith(COOKIE_NAME)){
    //          formCookie = cookie.replace(`${COOKIE_NAME}=`,"");
    //       }
    //     })
    //     setFormData(JSON.parse(formCookie))
    //   }
    const [message, setMessage] = useState('')
 const   createCookieInHour =(cookieName, cookieValue, hourToExpire) => {
        let date = new Date();
        date.setTime(date.getTime()+(hourToExpire*60*60*1000));
        document.cookie = cookieName + " = " + cookieValue + "; expires = " +date.toGMTString();
    }
  return (
    <>
        <div className="loader-wrapper">
            <div className="loader-box">
                <div className="loader-35"></div>
            </div>
        </div>
        <section>         
            <div className="container-fluid p-0">
                <div className="row">
                <div className="col-12">
                    <div className="login-card"><img className="img-fluid bg-img-cover" src="assets/images/bg.jpg" alt=""/>
                    {/* <form className="theme-form login-form">
                        <div className="login-header text-center">
                        <h4>Login</h4>
                        <h6>Welcome back! Log in to your account.</h6>
                        </div>
                        <div className="form-group">
                        <label>Email Address</label>
                        <div className="input-group"><span className="input-group-text"><i className="fa-duotone fa-user"></i></span>
                            <input className="form-control" type="email" required="" placeholder="Enter Your Email"/>
                        </div>
                        </div>
                        <div className="form-group">
                        <label>Password</label>
                        <div className="input-group"><span className="input-group-text"><i className="fa-duotone fa-lock"></i></span>
                            <input className="form-control" type="password" name="password" required="" placeholder="*********"/>
                            <div className="show-hide"><span className="show">                         </span></div>
                        </div>
                        </div>
                        <div className="form-group">
                        <div className="checkbox">
                            <input id="checkbox1" type="checkbox"/>
                            <label for="checkbox1">Remember password</label>
                        </div><a className="link" onClick={() => createCookieInHour('James','bond',1)}>Forgot password?</a>
                        </div>
                        <div className="form-group">
                        <button className="btn btn-primary btn-block" type="submit"><Link className='nav-link menu-title link-nav' to='/dashboard'>
                        Sign in
            </Link></button>
            </div> */}
            <form className="theme-form login-form"  onSubmit={handleSubmit}>
              <div className="login-header text-center">
                <h4>Login</h4>
                <h6>Welcome back! Log in to your account.</h6>
              </div>
              <div className="form-group">
                <label>Email Address</label>
                <div className="input-group"><span className="input-group-text"><i className="fa-duotone fa-user"></i></span>
                
                  <input className="form-control"  label='Email'
          type='email'
          required
          onChange={handleChange}
          name='email'
          value={email}  placeholder="Enter Your Email"/>
                </div>
              </div>
              <div className="form-group">
                <label>Password</label>
                <div className="input-group"><span className="input-group-text"><i className="fa-duotone fa-lock"></i></span>
                  <input className="form-control" label='Password'
                   type='password'
                   required
          onChange={handleChange}
          name='password'
          value={password} placeholder="*********"/>
                  <div className="show-hide"><span className="show">                         </span></div>
                </div>
              </div>
              <div className="form-group">
                <div className="checkbox">
                  <input id="checkbox1" type="checkbox"/>
                  <label for="checkbox1">Remember password</label>
                </div><a className="link" href="#" onClick={() => createCookieInHour('James','bond',1)}>Forgot password?</a>
              </div>
              <div className="form-group">
                <button className="btn btn-primary btn-block" type="submit">Sign in</button>
                {/* <Button buttonType='google' type='button' onClick={signInWithGoogle}>
                  Sign In With Google
                </Button> */}
              </div>
                        <section className="fxt-template-animation fxt-template-layout7" id="register">
              <ul className="fxt-socials">
								    <li className="fxt-facebook">
										<div className="fxt-transformY-50 fxt-transition-delay-6">
											<a href="javascript:void(0);" onClick={handleFacebookLogin} disabled={isLoading} title="facebook"><i className="fab fa-facebook"></i><span>Facebook</span></a>
										</div>
									</li>
									<li className="fxt-google">
										<div className="fxt-transformY-50 fxt-transition-delay-6">
											<a onClick={signInWithGoogle} title="google"><i className="fab fa-google"></i><span>Google</span></a>
										</div>
									</li>
									<li className="fxt-microsoft">
										<div className="fxt-transformY-50 fxt-transition-delay-6">
											<a href="javascript:void(0);" onClick={handleMicrosoftLogin} title="Microsoft"><i className="fab fa-microsoft"></i><span>Microsoft</span></a>
                    </div>
									</li>
									<li className="fxt-apple">
										<div className="fxt-transformY-50 fxt-transition-delay-6">
											
                      <a href="javascript:void(0);" onClick={handleAppleLogin} title="Apple"><i className="fab fa-apple"></i><span>Apple</span></a>
										</div>
									</li>
                  <li className="fxt-facebook">
										<div className="fxt-transformY-50 fxt-transition-delay-6">
											<a href="javascript:void(0);" onClick={handleGitHubLogin} title="Apple"><i className="fa-brands fa-github"></i><span>GitHub</span></a>
										</div>
									</li>
                  <li className="fxt-microsoft">
										<div className="fxt-transformY-50 fxt-transition-delay-6">
											<a href="javascript:void(0);" onClick={handleTwitterLogin} title="Microsoft"><i className="fab fa-twitter"></i><span>Twitter</span></a>
                    </div>
									</li>
								</ul>
                </section>
                       
                    </form>
                    </div>
                </div>
                </div>
            </div>
        </section>
       
    </>
    
  )
}

export default Login
import React from 'react'

export default function Header() {
  return (
    <>
        <div className="page-main-header">
        <div className="main-header-right row m-0">
          <div className="main-header-left">
          <div className="logo-wrapper"><a href="index.html"><img className="img-fluid" src="assets/images/logo-m.png" alt=""/></a></div>
          </div>
          <div className="toggle-sidebar"><i className="status_toggle middle f-16 fa-duotone fa-bars-staggered" data-feather="align-center" id="sidebar-toggle"></i></div>
          <div className="left-menu-header col">
            <ul>
              {/* <li>
                <form className="form-inline search-form">
                  <div className="search-bg"><i className="fa fa-search"></i></div>
                  <input className="form-control-plaintext" placeholder="Search here....."/>
                </form><span className="d-sm-none mobile-search search-bg"><i className="fa fa-search"></i></span>
              </li> */}
            </ul>
          </div>
          <div className="nav-right col pull-right right-menu">
            <ul className="nav-menus">
              <li>
                <div className="notification-box"><a href="#" className="text-dark"><i className="fa-regular fa-envelope f-20"></i></a></div>
              </li>
              <li className="onhover-dropdown">
                <div className="notification-box"><i className="fa-solid fa-planet-moon f-20"></i><span className="badge badge-pill badge-success">4</span></div>
                <div className="notification-dropdown cart onhover-show-div">
                  <div className="m-3">
                    <div className="row">
                      <div className="col">
                        <p> <b>4 </b>Live Website pending for Approval</p>
                      </div>
                    </div><a className="btn btn-dark w-100 mt-1" href="#">Process To Approve</a>
                  </div>
                  <ul className="border-top custom-scrollbar"> 
                    <li>
                      <p className="mb-0 ps-4 p-relative"><a href="#" className="f-14"><i className="fa fa-circle me-3 f-10 font-black"> </i>Foodcourt is live waiting for approval</a></p>
                    </li>
                    <li>
                      <p className="mb-0 ps-4 p-relative"><a href="#" className="f-14"><i className="fa fa-circle me-3 f-10 font-black"></i>Restaurant is live waiting for approval</a></p>
                    </li>
                    <li>
                      <p className="mb-0 ps-4 p-relative"><a href="#" className="f-14"><i className="fa fa-circle me-3 f-10 font-black"> </i>Foodcourt is live waiting for approval</a></p>
                    </li>
                    <li>
                      <p className="mb-0 ps-4 p-relative"><a href="#" className="f-14"><i className="fa fa-circle me-3 f-10 font-black"></i>Restaurant is live waiting for approval</a></p>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="onhover-dropdown">
                <div className="notification-box"><i className="fa-regular fa-bell f-20"></i><span className="badge badge-pill badge-primary">4</span></div>
                <div className="notification-dropdown onhover-show-div">
                  <div className="m-3">
                    <p> <b>4 </b>You have new notifications</p>
                  </div>
                  <ul className="border-top">
                    <li>
                      <p className="mb-0 ps-4 p-relative"><a href="#" className="f-14"><i className="fa fa-circle me-3 f-10 font-black"> </i>Foodcourt is live <span className="pull-right">10 min.</span></a></p>
                    </li>
                    <li>
                      <p className="mb-0 ps-4 p-relative"><a href="#" className="f-14"><i className="fa fa-circle me-3 f-10 font-black"></i>Ticket Generated from Ajay <span className="pull-right">32 min.</span></a></p>
                    </li>
                    <li>
                      <p className="mb-0 ps-4 p-relative"><a href="#" className="f-14"><i className="fa fa-circle me-3 f-10 font-black"></i>New User Registered <span className="pull-right">1 hr</span></a></p>
                    </li>
                    <li>
                      <p className="mb-0 ps-4 p-relative"><a href="#" className="f-14"><i className="fa fa-circle me-3 f-10 font-black"></i>New website save as draft <span className="pull-right">2 hr  </span></a></p>
                    </li>
                  </ul>
                  <div className="m-3 mt-1"><a className="btn btn-light w-100" href="#">View all</a></div>
                </div>
              </li>
              <li className="onhover-dropdown">
                <div className="notification-box"><i className="fa-regular fa-ticket f-20"></i><span className="badge badge-pill badge-warning">4</span></div>
                <div className="chat-dropdown onhover-show-div">                   
                  <div className="m-3">
                    <a className="btn btn-dark w-100" href="#">You have 3 New Tickets</a>
                  </div>
                  <ul className="border-top custom-scrollbar"> 
                    <li>
                      <div className="media">
                        <div className="media-body"><a href="#"><span>Manoj Kumar</span></a>
                          <p className="f-12 dark-font">Lorem Ipsum is simply dummy text of the printing</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="media">
                        <div className="media-body"><a href="#"><span>Rohit Kumar</span></a>
                          <p className="f-12">Lorem Ipsum is simply dummy text of the printing</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="media">
                        <div className="media-body"><a href="#"><span>Himanshu Kumar</span></a>
                          <p className="f-12">Lorem Ipsum is simply dummy text of the printing</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="m-3"><a className="btn btn-light w-100" href="#">View all</a></div>
                </div>
              </li>
              <li>
                <span className="text-dark nav-settings">
                  <i className="fa-sharp fa-solid fa-ellipsis-stroke-vertical f-20"></i>
                </span>
              </li>
              <li><a className="text-dark" href="#!" id="viewfullscreen" onClick="toggleFullScreen()"><i className="fa-duotone fa-expand f-20"></i></a></li>
              <li className="onhover-dropdown">
                <div className="media profile-media">
                <img className="rounded-circle" src="assets/images/pf.png" alt=""/>
                </div>
                <ul className="profile-dropdown onhover-show-div">
                  <li><a href="user-profile.html"><i className="f-16 fa-duotone fa-user m-r-5"></i><span>Account </span></a></li>
                  <li><a className="btn btn-light w-100" href="#"><i data-feather="log-in"></i>Log out</a></li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="d-lg-none col mobile-toggle pull-right"><i className="fa-solid fa-ellipsis"></i></div>
        </div>
      </div>
    </>
  )
}


import Header from './Header';
import SideNav from './SideNav';
import Footer from './Footer';
import React, { useEffect } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
export default function LunchMenu() {


  return (
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <div className="loader-wrapper">
        <div className="loader-box">
            <div className="loader-35"></div>
        </div>
      </div>
      <Header></Header>
      <div className="page-body-wrapper sidebar-icon">
        <SideNav></SideNav>
       <div className="page-body ecommerce-dash">
          <div className="container-fluid">
            <div className="page-header dash-breadcrumb">
              <div className="row">
                <div className="col-4">                              
                  <h3 className="f-w-600">View/Edit Lunch Menu</h3>
                </div>
                <div className="col-md-4 text-center">
                  <div className="btn-group">
                    <button className="btn btn-primary" title="Copy"><i className="fa-solid fa-copy"></i></button>
                    <button className="btn btn-secondary" title="Excel"><i className="fa-solid fa-file-spreadsheet"></i></button>
                    <button className="btn btn-success" title="PDF"><i className="fa-solid fa-file-pdf"></i></button>
                    <div className="btn-group" role="group">
                      <button className="btn btn-light dropdown-toggle" id="btnGroupDrop1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Filter</button>
                      <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                        <a className="dropdown-item" href="#">Veg</a>
                        <a className="dropdown-item" href="#">Non Veg</a>
                        <a className="dropdown-item" href="#">Vegan</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="breadcrumb-sec">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li>
                      <li className="breadcrumb-item">Dashboard</li>
                      <li className="breadcrumb-item active">View/Edit Lunch Menu</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row size-column">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header pb-0 d-block">
                    <div className="row">
                      <div className="col-md-6">
                        <h5>View/Edit Lunch Menu</h5>
                      </div>
                      
                    </div>
                  </div>
                  <div className="card-body">
                  <div className="table-responsive">
                      <table id="example" className="table table-striped dt-responsive nowrap table-hover">
                        <thead>
                            <tr>
                                <th>S No</th>
                                <th>Image</th>
                                <th>Product Name</th>
                                <th>Description</th>
                                <th>Cate</th>
                                <th>Spicy</th>
                                <th>L/G/V</th>
                                <th>Price</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td><img src="assets/images/d1.jpg" alt="" className="dish-img"/></td>
                                <td>Aloo Gobi</td>
                                <td className="dish-desc">Potato and cauliflower curry in masala sauce</td>
                                <td>Veg</td>
                                <td><img src="assets/images/chilly1.png" alt="" className="spicy"/></td>
                                <td>L G V</td>
                                <td>€13.50</td>
                                <td>
                                  <button className="btn btn-pill btn-outline-primary btn-xs" type="button" title="Edit"  data-bs-toggle="modal" data-bs-target="#EditMainMenu"><i className="fa-solid fa-pen-to-square"></i></button>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Stop Selling"><i className="fa-regular fa-circle-xmark"></i></button>
                                  <button className="btn btn-pill btn-outline-danger btn-xs" type="button" title="Delete"><i className="fa-solid fa-trash"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td><img src="assets/images/d2.jpg" alt="" className="dish-img"/></td>
                                <td>Chana Masala</td>
                                <td className="dish-desc">Chickpeas in tomato-masala sauce</td>
                                <td>Veg</td>
                                <td><img src="assets/images/chilly1.png" alt="" className="spicy"/></td>
                                <td>L G V</td>
                                <td>€13.90</td>
                                <td>
                                  <button className="btn btn-pill btn-outline-primary btn-xs" type="button" title="Edit"  data-bs-toggle="modal" data-bs-target="#EditMainMenu"><i className="fa-solid fa-pen-to-square"></i></button>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Stop Selling"><i className="fa-regular fa-circle-xmark"></i></button>
                                  <button className="btn btn-pill btn-outline-danger btn-xs" type="button" title="Delete"><i className="fa-solid fa-trash"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td><img src="assets/images/d3.jpg" alt="" className="dish-img"/></td>
                                <td>Dal Makhani</td>
                                <td className="dish-desc">Slow cooked black lentils in rich butter-cream sauce</td>
                                <td>Veg</td>
                                <td><img src="assets/images/chilly1.png" alt="" className="spicy"/></td>
                                <td>L G</td>
                                <td>€14.50</td>
                                <td>
                                  <button className="btn btn-pill btn-outline-primary btn-xs" type="button" title="Edit"  data-bs-toggle="modal" data-bs-target="#EditMainMenu"><i className="fa-solid fa-pen-to-square"></i></button>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Stop Selling"><i className="fa-regular fa-circle-xmark"></i></button>
                                  <button className="btn btn-pill btn-outline-danger btn-xs" type="button" title="Delete"><i className="fa-solid fa-trash"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td><img src="assets/images/d4.jpg" alt="" className="dish-img"/></td>
                                <td>Palak Paneer</td>
                                <td className="dish-desc">Paneer cheese in spinach butter sauce</td>
                                <td>Veg</td>
                                <td><img src="assets/images/chilly1.png" alt="" className="spicy"/></td>
                                <td>G</td>
                                <td>€14.90</td>
                                <td>
                                  <button className="btn btn-pill btn-outline-primary btn-xs" type="button" title="Edit"  data-bs-toggle="modal" data-bs-target="#EditMainMenu"><i className="fa-solid fa-pen-to-square"></i></button>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Stop Selling"><i className="fa-regular fa-circle-xmark"></i></button>
                                  <button className="btn btn-pill btn-outline-danger btn-xs" type="button" title="Delete"><i className="fa-solid fa-trash"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td><img src="assets/images/d5.jpg" alt="" className="dish-img"/></td>
                                <td>Punjabi Soya Chaap</td>
                                <td className="dish-desc">Herb marinated soya sticks in sweet and sour-chili-masala sauce</td>
                                <td>Veg</td>
                                <td><img src="assets/images/chilly2.png" alt="" className="spicy"/></td>
                                <td>L G V</td>
                                <td>€15.50 </td>
                                <td>
                                  <button className="btn btn-pill btn-outline-primary btn-xs" type="button" title="Edit"  data-bs-toggle="modal" data-bs-target="#EditMainMenu"><i className="fa-solid fa-pen-to-square"></i></button>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Stop Selling"><i className="fa-regular fa-circle-xmark"></i></button>
                                  <button className="btn btn-pill btn-outline-danger btn-xs" type="button" title="Delete"><i className="fa-solid fa-trash"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td><img src="assets/images/d6.jpg" alt="" className="dish-img"/></td>
                                <td>Paneer Butter Masala</td>
                                <td className="dish-desc">Paneer cheesewith fresh paprika in creamy cashew-butter-masala sauce</td>
                                <td>Veg</td>
                                <td><img src="assets/images/chilly2.png" alt="" className="spicy"/></td>
                                <td>L G</td>
                                <td>€15.90</td>
                                <td>
                                  <button className="btn btn-pill btn-outline-primary btn-xs" type="button" title="Edit"  data-bs-toggle="modal" data-bs-target="#EditMainMenu"><i className="fa-solid fa-pen-to-square"></i></button>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Stop Selling"><i className="fa-regular fa-circle-xmark"></i></button>
                                  <button className="btn btn-pill btn-outline-danger btn-xs" type="button" title="Delete"><i className="fa-solid fa-trash"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td><img src="assets/images/d7.jpg" alt="" className="dish-img"/></td>
                                <td>Chicken Curry</td>
                                <td className="dish-desc">Stewed chicken in curry sauce</td>
                                <td>Non Veg</td>
                                <td><img src="assets/images/chilly1.png" alt="" className="spicy"/></td>
                                <td>L G</td>
                                <td>€13.90</td>
                                <td>
                                  <button className="btn btn-pill btn-outline-primary btn-xs" type="button" title="Edit"  data-bs-toggle="modal" data-bs-target="#EditMainMenu"><i className="fa-solid fa-pen-to-square"></i></button>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Stop Selling"><i className="fa-regular fa-circle-xmark"></i></button>
                                  <button className="btn btn-pill btn-outline-danger btn-xs" type="button" title="Delete"><i className="fa-solid fa-trash"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td><img src="assets/images/d8.jpg" alt="" className="dish-img"/></td>
                                <td>Chicken Masala</td>
                                <td className="dish-desc">Chicken leg piecesin curry-masala sauce</td>
                                <td>Non Veg</td>
                                <td><img src="assets/images/chilly2.png" alt="" className="spicy"/></td>
                                <td>L G</td>
                                <td>€15.50</td>
                                <td>
                                  <button className="btn btn-pill btn-outline-primary btn-xs" type="button" title="Edit"  data-bs-toggle="modal" data-bs-target="#EditMainMenu"><i className="fa-solid fa-pen-to-square"></i></button>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Stop Selling"><i className="fa-regular fa-circle-xmark"></i></button>
                                  <button className="btn btn-pill btn-outline-danger btn-xs" type="button" title="Delete"><i className="fa-solid fa-trash"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td><img src="assets/images/d9.jpg" alt="" className="dish-img"/></td>
                                <td>Garlic Chicken</td>
                                <td className="dish-desc">Stewed chicken in creamy garlic sauce</td>
                                <td>Non Veg</td>
                                <td><img src="assets/images/chilly1.png" alt="" className="spicy"/></td>
                                <td>L G</td>
                                <td>€15.00</td>
                                <td>
                                  <button className="btn btn-pill btn-outline-primary btn-xs" type="button" title="Edit"  data-bs-toggle="modal" data-bs-target="#EditMainMenu"><i className="fa-solid fa-pen-to-square"></i></button>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Stop Selling"><i className="fa-regular fa-circle-xmark"></i></button>
                                  <button className="btn btn-pill btn-outline-danger btn-xs" type="button" title="Delete"><i className="fa-solid fa-trash"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td><img src="assets/images/d10.jpg" alt="" className="dish-img"/></td>
                                <td>Tandoori Chicken Biryani</td>
                                <td className="dish-desc">Tandoor grilled chicken, basmati rice and vegetables</td>
                                <td>Non Veg</td>
                                <td><img src="assets/images/chilly2.png" alt="" className="spicy"/></td>
                                <td>L G</td>
                                <td>€14.90</td>
                                <td>
                                  <button className="btn btn-pill btn-outline-primary btn-xs" type="button" title="Edit"  data-bs-toggle="modal" data-bs-target="#EditMainMenu"><i className="fa-solid fa-pen-to-square"></i></button>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Stop Selling"><i className="fa-regular fa-circle-xmark"></i></button>
                                  <button className="btn btn-pill btn-outline-danger btn-xs" type="button" title="Delete"><i className="fa-solid fa-trash"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td><img src="assets/images/d11.jpg" alt="" className="dish-img"/></td>
                                <td>Butter Chicken</td>
                                <td className="dish-desc">World-famous dish. Tandoori oven grilled, marinated chicken in rich tomato-butter-cream- sauce.</td>
                                <td>Non Veg</td>
                                <td><img src="assets/images/chilly1.png" alt="" className="spicy"/></td>
                                <td>L G</td>
                                <td>€15.90</td>
                                <td>
                                  <button className="btn btn-pill btn-outline-primary btn-xs" type="button" title="Edit"  data-bs-toggle="modal" data-bs-target="#EditMainMenu"><i className="fa-solid fa-pen-to-square"></i></button>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Stop Selling"><i className="fa-regular fa-circle-xmark"></i></button>
                                  <button className="btn btn-pill btn-outline-danger btn-xs" type="button" title="Delete"><i className="fa-solid fa-trash"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td><img src="assets/images/d12.jpg" alt="" className="dish-img" /></td>
                                <td>Baingan Bharta</td>
                                <td className="dish-desc">Tandoori oven grilled, green chili seasoned eggplant in tomato-ginger-masala sauce</td>
                                <td>Veg</td>
                                <td><img src="assets/images/chilly1.png" alt="" className="spicy"/></td>
                                <td>L G V </td>
                                <td>€15.50</td>
                                <td>
                                  <button className="btn btn-pill btn-outline-primary btn-xs" type="button" title="Edit"  data-bs-toggle="modal" data-bs-target="#EditMainMenu"><i className="fa-solid fa-pen-to-square"></i></button>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Stop Selling"><i className="fa-regular fa-circle-xmark"></i></button>
                                  <button className="btn btn-pill btn-outline-danger btn-xs" type="button" title="Delete"><i className="fa-solid fa-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
     <Footer></Footer>
       </div>
     </div>
  )
}

import React, { useEffect } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
import Header from './Header';
import SideNav from './SideNav';
import Footer from './Footer';
const Dashboard = () => {

    return (
      <>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <div className="loader-wrapper">
        <div className="loader-box">
            <div className="loader-35"></div>
        </div>
      </div>
      <Header></Header>
      <div className="page-body-wrapper sidebar-icon">
        <SideNav></SideNav>
        <div className="page-body ecommerce-dash">
          <div className="container-fluid">
            <div className="page-header dash-breadcrumb">
              <div className="row">
                <div className="col-6">                              
                  <h3 className="f-w-600">Website Profile</h3>
                </div>
                <div className="col-6">
                  <div className="breadcrumb-sec">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li>
                      <li className="breadcrumb-item">Website Profile</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row size-column">
              <div className="col-xl-12 box-col-12">
                <div className="card perform-overview-sec">
                  <div className="card-header">
                    
                  </div>
                  <div className="card-body p-t-0">
                    <div className="row overview justify-content-center g-3">
                      <div className="col-md-3">
                        <div className="card m-b-10">
                          <div className="card-body">
                            <div className="media">
                              <div className="media-body">
                                <ul className="widget-card">
                                  <li className="m-b-15"><i className="fa-duotone fa-file f-20 me-2"></i><span className="f-16 font-secondary">Website Pages</span></li>
                                  <li>
                                    <h4 className="counter">6</h4>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card">
                          <div className="card-body">
                            <div className="media">
                              <div className="media-body">
                                <ul className="widget-card">
                                  <li className="m-b-15"><i className="fa-solid fa-eye f-20 me-2"></i><span className="f-16 font-secondary">Website Views</span></li>
                                  <li>
                                    <h4 className="counter">195</h4>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card">
                          <div className="card-body">
                            <div className="media">
                              <div className="media-body">
                                <ul className="widget-card">
                                  <li className="m-b-15"><i className="fa-solid fa-list f-20 me-2"></i><span className="f-16 font-secondary">Menu Items</span></li>
                                  <li>
                                    <h4 className="counter">12</h4>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card">
                          <div className="card-body">
                            <div className="media">
                              <div className="media-body">
                                <ul className="widget-card">
                                  <li className="m-b-15"><i className="fa-sharp fa-solid fa-server f-20 me-2"></i><span className="f-16 font-secondary">Hosting Days Left</span></li>
                                  <li>
                                    <h4 className="counter">270</h4>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-profile">
              <div className="row">
                <div className="col-xl-4">
                  <div className="card">
                    <div className="card-header pb-0">
                      <h5 className="card-title mb-0">Website Profile</h5>
                    </div>
                    <div className="card-body post-about">
                      <div className="profile-title text-center">
                        <img className="w-100 pf-img" alt="" src="assets/images/masala.png"/>
                      </div>
                      <ul>
                        <li>
                          <div className="icon"><i className="fa-solid fa-building"></i></div>
                          <div>
                            <h5>Ravintola Makalu</h5>
                            <p>Restaurant name</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon"><i className="fa-solid fa-phone-office"></i></div>
                          <div>
                            <h5>0505958400</h5>
                            <p>Contact number</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon"><i className="fa-solid fa-envelope"></i></div>
                          <div>
                            <h5>info@ravintolamakalu.fi</h5>
                            <p>Email ID</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon"><i className="fa-solid fa-map-location-dot"></i></div>
                          <div>
                            <h5>Ravintola Makalu Pikkalankatu 5, 00700 Helsinki</h5>
                            <p>Address</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon"><i className="fa-solid fa-globe"></i></div>
                          <div>
                            <h5><a href="https://ravintolamakalu.fi" target="_blank">https://ravintolamakalu.fi</a></h5>
                            <p>Website URL</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon"><i className="fa-solid fa-calendar-check"></i></div>
                          <div>
                            <h5>16/02/2023</h5>
                            <p>Hosting Start Date</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon"><i className="fa-solid fa-calendar-xmark"></i></div>
                          <div>
                            <h5>16/02/2024</h5>
                            <p>Hosting End Date</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8">
                  <form className="card">
                    <div className="card-header pb-0">
                      <h5 className="card-title mb-0">Edit Profile</h5>
                    </div>
                    <div className="card-body pb-0">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-4">
                            <label className="form-label">Restaurant Name</label>
                            <input className="form-control" type="text" value="Ravintola Makalu" placeholder="Restaurant Name"/>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="mb-4">
                            <label className="form-label">Website URL</label>
                            <input className="form-control" type="url" value="https://ravintolamakalu.fi" placeholder="Website URL" readonly/>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="mb-4">
                            <label className="form-label">Email ID</label>
                            <input className="form-control" type="email" value="info@ravintolamakalu.fi" placeholder="Email ID"/>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="mb-4">
                            <label className="form-label">Contact No</label>
                            <input className="form-control" type="text" value="0505958400" placeholder="Contact No"/>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-12">
                          <div className="mb-4">
                            <label className="form-label">Address</label>
                            <input className="form-control" type="text" value="Ravintola Makalu Pikkalankatu 5, 00700 Helsinki" placeholder="Address"/>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="mb-4">
                            <label className="form-label">PIN Code</label>
                            <input className="form-control" type="text" value="00700" placeholder="PIN Code"/>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="mb-4">
                            <label className="form-label">Logo</label>
                            <input className="form-control" type="file" placeholder="Logo"/>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-4">
                            <label className="form-label">Hosting Start Date</label>
                            <input className="form-control" type="date" value="2023-02-16" placeholder="Hosting Start Date" readonly/>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="mb-4">
                            <label className="form-label">Hosting End Date</label>
                            <input className="form-control" type="date" value="2024-02-16" placeholder="Hosting End Date" readonly/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer text-end pt-0">
                      <button className="btn btn-primary" type="submit">Update Profile</button>
                    </div>
                  </form>
                </div>
              </div>
          </div>



          <div id="right-sidebar" className="settings-panel">
            <i className="settings-close fa-regular fa-circle-xmark f-20"></i>
            
          </div>



          </div>
        </div>
        <div className="tap-top"><i className="fa-solid fa-arrow-turn-up"></i></div>
     
      
        <Footer></Footer>
      </div>
    </div>
      </>
      );
  };
  
  export default Dashboard;
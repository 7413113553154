import React,{ useState,useEffect } from 'react';


import FormInput from '../form-input/form-input.component';
import Button from '../button/button.component';
import { API_HOST } from "../../api_utils";
import {
  signInAuthUserWithEmailAndPassword,
  signInWithGooglePopup,
} from '../../utils/firebase/firebase.utils';
import axios from "axios";
import './sign-in-form.styles.scss';

const defaultFormFields = {
  email: '',
  password: '',
};

const SignInForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [isLoading, setIsLoading] = useState(false);
  const { email, password } = formFields;

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const signInWithGoogle = async () => {
    await signInWithGooglePopup();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(event);
    var data = JSON.stringify({
      "user_name": "Sanit Kale",
      "user_profile_img": "",
      "user_profession": "",
      "user_about": "",
      "user_skills": "",
      "user_dob": "1970-09-12",
      "user_address": "",
      "user_email": email,
      "user_phone": "",
      "user_website": "",
      "user_category_id": "",
      "user_register_id": "zktorZwB461dSLRCKcWV",
      "user_profile_background": "",
      "user_password": password,
      "user_logined_method": "email",
      "app_first_fill": "1",
      "app_auth": email,
      "user_profile_name": "jk",
      "user_channel_id": "zktorbpmLIDjKTxZi47I",
      "user_login_history_id": "613e13fad9fd38f0",
      "user_gender": "Male",
      "user_age": "41",
      "caller_id": "=OGCj(6&(5A%PDhBGgQ2)|zTk"
    });
    var config = {
      method: 'post',
    maxBodyLength: Infinity,
      url: `${API_HOST}profile_insert`,
      headers: { 
        'Authorization': '', 
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  };
  // const handleGitHubLogin = async () => {
  //  // setIsLoading(true);
  //   try {
  //     const response = await fetch(`https://github.com/login/oauth/authorize?client_id=15f9cc86c96d14536717&scope=user`);
  //     const data = await response.json();
  //     // Do something with the data, such as save the access token
  //   } catch (error) {
  //     console.error(error);
  //   }
  //  // setIsLoading(false);
  // };
  function handleGitHubLogin() {
    // Generate a random state parameter
    const state = Math.random().toString(36).substr(2);
  
    // Save the state parameter to localStorage
    localStorage.setItem('githubState', state);
  
    // Construct the GitHub OAuth URL
    const url = `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&scope=user&state=${state}`;
  
    // Open the GitHub login page in a popup
    const width = 600;
    const height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const features = `width=${width},height=${height},left=${left},top=${top},menubar=no,toolbar=no`;
    const popup = window.open(url, 'GitHub Login', features);
  
    // Listen for messages from the popup window
    window.addEventListener('message', event => {
      if (event.origin === window.location.origin && event.source === popup) {
        // Verify that the state parameter matches the one we saved
        if (event.data.type === 'github:login' && event.data.state === state) {
          // Save the access token to localStorage or a state variable
          localStorage.setItem('githubAccessToken', event.data.access_token);
  
          // Close the popup window
          popup.close();
  
          // Redirect the user to the home page
          window.location.href = '/';
        } else if (event.data.type === 'github:error') {
          console.error('GitHub login error:', event.data.error_description);
        }
      }
    });
  }

  function handleMicrosoftLogin ()  {
    const clientId="ef6d8c0c-4968-4c20-866e-ac765ee9ea15";
    const redirectUri="https://medium.com/zestgeek/login-with-github-and-microsoft-in-reactjs-e33ffbcd933";
    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=token&redirect_uri=${redirectUri}&scope=user.read`;

  
    const width = 600;
    const height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const features = `width=${width},height=${height},left=${left},top=${top},menubar=no,toolbar=no`;
    const popup = window.open(authUrl,  'Microsoft Login', features);
  
    const handlePopupMessage = (event) => {
      if (event.origin !== window.location.origin){
        console.log('test');
        return;
      } 
      if (event.source !== popup) return;

      const authResponse = JSON.parse(event.data);
      console.log(authResponse);
      // Save the access token to localStorage or a state variable
      window.removeEventListener('message', handlePopupMessage);
      popup.close();
    };

    window.addEventListener('message', handlePopupMessage);
  
  }
  function handleAppleLogin  () {
    const redirectUri = encodeURIComponent(`${window.location.origin}/apple-redirect`);
    const state = encodeURIComponent(Math.random().toString(36).substring(2, 15));
    const nonce = encodeURIComponent(Math.random().toString(36).substring(2, 15));

    const authUrl = `https://appleid.apple.com/auth/authorize?client_id=${process.env.REACT_APP_APPLE_CLIENT_ID}&redirect_uri=${redirectUri}&state=${state}&response_type=code%20id_token&response_mode=form_post&scope=email%20name&nonce=${nonce}`;

    const width = 600;
    const height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    const popup = window.open(
      authUrl,
      'Apple Login',
      `width=${width},height=${height},left=${left},top=${top}`
    );

    const handlePopupMessage = (event) => {
      if (event.origin !== window.location.origin) return;
      if (event.source !== popup) return;

      const authResponse = event.data;
      console.log(authResponse);
      // Save the access token to localStorage or a state variable
      window.removeEventListener('message', handlePopupMessage);
      popup.close();
    };

    window.addEventListener('message', handlePopupMessage);
  }
  function handleTwitterLogin() {
    // const handleLogin = () => {
      const redirectUri = encodeURIComponent(window.location.origin);
      const authUrl = `https://api.twitter.com/oauth/authenticate?client_id=${process.env.REACT_APP_TWITTER_CLIENT_ID}&response_type=token&redirect_uri=${redirectUri}`;
  
      const width = 600;
      const height = 600;
      const left = window.innerWidth / 2 - width / 2;
      const top = window.innerHeight / 2 - height / 2;
  
      const popup = window.open(
        authUrl,
        'Twitter Login',
        `width=${width},height=${height},left=${left},top=${top}`
      );
  
      const handlePopupMessage = (event) => {
        if (event.origin !== window.location.origin) {
          return;
        }
  
        try {
          const authResponse = JSON.parse(event.data);
          console.log(authResponse);
          // Save the access token to localStorage or a state variable
          window.removeEventListener('message', handlePopupMessage);
          popup.close();
        } catch (e) {}
      };
  
      window.addEventListener('message', handlePopupMessage);
    };
  const handleFacebookLogin = async () => {
    setIsLoading(true);
    try {
      // Initialize the Facebook SDK
      await window.FB.init({
        appId: '185202659227880',
        cookie: true,
        xfbml: true,
        version: 'v12.0'
      });

      // Login the user
      const response = await window.FB.login();
      const { authResponse } = response;
      if (authResponse) {
        // User logged in successfully, fetch their profile data
        const profile = await fetch(`https://graph.facebook.com/v12.0/me?fields=name,email,picture&access_token=${authResponse.accessToken}`);
        const profileData = await profile.json();
        // Do something with the profile data, such as save the access token
      } else {
        console.error('User cancelled login or did not fully authorize.');
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <section>         
    <div className="container-fluid p-0">
      <div className="row">
        <div className="col-12">
          <div className="login-card">
            <form className="theme-form login-form"  onSubmit={handleSubmit}>
              <div className="login-header text-center">
                <h4>Login</h4>
                <h6>Welcome back! Log in to your account.</h6>
              </div>
              <div className="form-group">
                <label>Email Address</label>
                <div className="input-group"><span className="input-group-text"><i className="fa-duotone fa-user"></i></span>
                
                  <input className="form-control"  label='Email'
          type='email'
          required
          onChange={handleChange}
          name='email'
          value={email}  placeholder="Enter Your Email"/>
                </div>
              </div>
              <div className="form-group">
                <label>Password</label>
                <div className="input-group"><span className="input-group-text"><i className="fa-duotone fa-lock"></i></span>
                  <input className="form-control" label='Password'
                   type='password'
                   required
          onChange={handleChange}
          name='password'
          value={password} placeholder="*********"/>
                  <div className="show-hide"><span className="show">                         </span></div>
                </div>
              </div>
              <div className="form-group">
                <div className="checkbox">
                  <input id="checkbox1" type="checkbox"/>
                  <label for="checkbox1">Remember password</label>
                </div><a className="link" href="#">Forgot password?</a>
              </div>
              <div className="form-group">
                <button className="btn btn-primary btn-block" type="submit">Sign in</button>
                {/* <Button buttonType='google' type='button' onClick={signInWithGoogle}>
                  Sign In With Google
                </Button> */}
              </div>
              <section className="fxt-template-animation fxt-template-layout7" id="register">
              <ul className="fxt-socials">
								    <li className="fxt-facebook">
										<div className="fxt-transformY-50 fxt-transition-delay-6">
											<a href="javascript:void(0);" onClick={handleFacebookLogin} disabled={isLoading} title="facebook"><i className="fab fa-facebook"></i><span>Facebook</span></a>
										</div>
									</li>
									<li className="fxt-google">
										<div className="fxt-transformY-50 fxt-transition-delay-6">
											<a onClick={signInWithGoogle} title="google"><i className="fab fa-google"></i><span>Google</span></a>
										</div>
									</li>
									<li className="fxt-microsoft">
										<div className="fxt-transformY-50 fxt-transition-delay-6">
											<a href="javascript:void(0);" onClick={handleMicrosoftLogin} title="Microsoft"><i className="fab fa-microsoft"></i><span>Microsoft</span></a>
                    </div>
									</li>
									<li className="fxt-apple">
										<div className="fxt-transformY-50 fxt-transition-delay-6">
											
                      <a href="javascript:void(0);" onClick={handleAppleLogin} title="Apple"><i className="fab fa-apple"></i><span>Apple</span></a>
										</div>
									</li>
                  <li className="fxt-facebook">
										<div className="fxt-transformY-50 fxt-transition-delay-6">
											<a href="javascript:void(0);" onClick={handleGitHubLogin} title="Apple"><i className="fa-brands fa-github"></i><span>GitHub</span></a>
										</div>
									</li>
                  <li className="fxt-microsoft">
										<div className="fxt-transformY-50 fxt-transition-delay-6">
											<a href="javascript:void(0);" onClick={handleTwitterLogin} title="Microsoft"><i className="fab fa-twitter"></i><span>Twitter</span></a>
                    </div>
									</li>
								</ul>
                </section>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default SignInForm;

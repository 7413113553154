
const TemplateSiebar = (props) => (
  
<div className="col-lg-3">
							 	 <div className="text-center">
                
              {/* <label className="searchblabelox">Search Templates: </label> */}
              <input
                className="search-ox"
                type="search"
                value={props.keywords}
                placeholder="Search Template"
                onChange={props.onSearchChange}
              />
              <hr />
           
								  	<ul className="col container-filter portfolioFilte list-unstyled mb-0" id="filter">
                    <li><a className={ props.categoryid === '' ? 'categories active' : 'categories'} onClick={() => props.getCategory('')}>Show All ({props.totalRecordFilter})</a></li>
                    {props.category.map((cat) => {
                return (
										<li><a className={ props.categoryid === cat.category ? 'categories active' : 'categories'}   onClick={() => props.getCategory(cat.category)}>{cat.category} ({cat.count})</a></li>
                    );
              })}
								  	</ul>
							  	</div>
						  	</div>
);

export default TemplateSiebar;

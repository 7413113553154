import Header from './Header';
import SideNav from './SideNav';
import Footer from './Footer';
import React, { useEffect } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
export default function MenuOffers() {


  return (
  <>
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
       <div className="loader-wrapper">
        <div className="loader-box">
            <div className="loader-35"></div>
        </div>
       </div>
       <Header></Header>
      
       <div className="page-body-wrapper sidebar-icon">
        
        <SideNav></SideNav>
        <div className="page-body ecommerce-dash">
          <div className="container-fluid">
            <div className="page-header dash-breadcrumb">
              <div className="row">
                <div className="col-6">                              
                  <h3 className="f-w-600">Make Menu Offers</h3>
                </div>
                <div className="col-6">
                  <div className="breadcrumb-sec">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li>
                      <li className="breadcrumb-item">Dashboard</li>
                      <li className="breadcrumb-item active">Make Menu Offers</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row size-column">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header pb-0 d-block">
                  </div>
                  <div className="card-body">
                    <div className="row mb-3">
                      <div className="col-lg-6">
                          <label className="form-label"> Select category </label>
                          <select required="" className="form-select mb-3">
                            <option value=""> Select category </option>
                            <option value="52">Category</option>
                            <option value="53">Main Menu</option>
                          </select>
                      </div>
                      <div className="col-lg-6">
                          <label className="form-label"> Select menu items </label>
                          <select className="form-select mb-3">
                            <option value="334">Chicken soup - kanakeitto</option>
                            <option value="335">Bataatti-Chili soup</option>
                            <option value="336">Veg samosa (2 kpl/2pcs)</option>
                            <option value="337">Mixed Veg pakora</option>
                            <option value="338">Paneer pakora</option>
                            <option value="339">Chicken pakora</option>
                            <option value="340">Sweet &amp; sour chaat</option>
                            <option value="341">Paneer tikka salad</option>
                            <option value="342">Chicken tikka salad</option>
                            <option value="343">King prawn saslik salad </option>
                          </select>
                      </div>
                      <div className="col-lg-6">
                          <label className="form-label">Offer Type</label>
                          <select className="form-select mb-3">
                              <option value="">Not Applied</option>
                              <option value="1">Percentage</option>
                              <option value="2">Flat Amount</option>
                          </select>
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label">Discount:</label>
                        <input className="form-control mb-3" type="text" placeholder="Enter Discount Like 5"/>
                      </div>
                      <div className="col-lg-12 text-center">
                        <button className="btn btn-primary m-t-30" type="button"> Apply Offers </button>
                        <button className="btn btn-danger m-t-30" type="button"> Cancel </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="right-sidebar" className="settings-panel">
            <i className="settings-close fa-regular fa-circle-xmark f-20"></i>
          </div>
        </div>
        <div className="tap-top"><i className="fa-solid fa-arrow-turn-up"></i></div>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 footer-copyright">
                <p className="mb-0">Copyright 2023 © Softa All rights reserved.</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
    <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title">Add Gallery Image</h6>
            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="mb-3 row">
              <label className="form-label">Upload Image</label>
              <input className="form-control" type="file"/>
            </div>
            <div className="mb-3 row">
              <label className="form-label">Add Figure Caption</label>
              <input className="form-control" type="text"/>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
            <button className="btn btn-primary" type="button">Add Image</button>
          </div>
        </div>
      </div>
    </div>
    </>
    )
  }
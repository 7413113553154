import Header from './Header';
import SideNav from './SideNav';
import Footer from './Footer';
import React, { useEffect } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
export default function Gallery() {


  return (
  <>
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
       <div className="loader-wrapper">
        <div className="loader-box">
            <div className="loader-35"></div>
        </div>
      </div>
      <Header></Header>
      
      <div className="page-body-wrapper sidebar-icon">
        
      <SideNav></SideNav>
        <div class="page-body ecommerce-dash">
          <div class="container-fluid">
            <div class="page-header dash-breadcrumb">
              <div class="row">
                <div class="col-6">                              
                  <h3 class="f-w-600">Table Booking Request</h3>
                </div>
                <div class="col-6">
                  <div class="breadcrumb-sec">
                    <ul class="breadcrumb">
                      <li class="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li>
                      <li class="breadcrumb-item">Dashboard</li>
                      <li class="breadcrumb-item active">Table Booking Request</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid">
            <div class="row size-column">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header pb-0 d-block">
                    <div class="row">
                      <div class="col-md-6">
                        
                      </div>
                      <div class="col-md-6 text-end">
                        <button class="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#exampleModalCenter"> Add Gallery Image </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="row gallery my-gallery" id="aniimated-thumbnials5" itemscope="" data-pswp-uid="6">
                          <figure class="col-md-3 col-6 box-col-4 xl-33 img-hover hover-6" itemprop="associatedMedia" itemscope=""><a href="assets/images/g1.jpg" itemprop="contentUrl" data-size="1600x950">
                              <div><img src="assets/images/g1.jpg" itemprop="thumbnail" alt="Image description"/></div></a>
                            <figcaption itemprop="caption description">Image caption  1</figcaption>
                            <div class="but-edit">
                              <button class="btn btn-pill btn-outline-primary btn-air-primary btn-xs" type="button"><i class="fa-solid fa-pen-to-square"></i></button>
                              <button class="btn btn-pill btn-outline-secondary btn-air-secondary btn-xs" type="button"><i class="fa-solid fa-trash"></i></button>
                            </div>
                          </figure>
                          <figure class="col-md-3 col-6 box-col-4 xl-33 img-hover hover-6" itemprop="associatedMedia" itemscope=""><a href="assets/images/g2.jpg" itemprop="contentUrl" data-size="1600x950">
                              <div><img src="assets/images/g2.jpg" itemprop="thumbnail" alt="Image description"/></div></a>
                            <figcaption itemprop="caption description">Image caption  2</figcaption>
                            <div class="but-edit">
                              <button class="btn btn-pill btn-outline-primary btn-air-primary btn-xs" type="button"><i class="fa-solid fa-pen-to-square"></i></button>
                              <button class="btn btn-pill btn-outline-secondary btn-air-secondary btn-xs" type="button"><i class="fa-solid fa-trash"></i></button>
                            </div>
                          </figure>
                          <figure class="col-md-3 col-6 box-col-4 xl-33 img-hover hover-6" itemprop="associatedMedia" itemscope=""><a href="assets/images/g3.jpg" itemprop="contentUrl" data-size="1600x950">
                              <div><img src="assets/images/g3.jpg" itemprop="thumbnail" alt="Image description"/></div></a>
                            <figcaption itemprop="caption description">Image caption  3</figcaption>
                            <div class="but-edit">
                              <button class="btn btn-pill btn-outline-primary btn-air-primary btn-xs" type="button"><i class="fa-solid fa-pen-to-square"></i></button>
                              <button class="btn btn-pill btn-outline-secondary btn-air-secondary btn-xs" type="button"><i class="fa-solid fa-trash"></i></button>
                            </div>
                          </figure>
                          <figure class="col-md-3 col-6 box-col-4 xl-33 img-hover hover-6" itemprop="associatedMedia" itemscope=""><a href="assets/images/g4.jpg" itemprop="contentUrl" data-size="1600x950">
                              <div><img src="assets/images/g4.jpg
                                " itemprop="thumbnail" alt="Image description"/></div></a>
                            <figcaption itemprop="caption description">Image caption  4</figcaption>
                            <div class="but-edit">
                              <button class="btn btn-pill btn-outline-primary btn-air-primary btn-xs" type="button"><i class="fa-solid fa-pen-to-square"></i></button>
                              <button class="btn btn-pill btn-outline-secondary btn-air-secondary btn-xs" type="button"><i class="fa-solid fa-trash"></i></button>
                            </div>
                          </figure>
                          <figure class="col-md-3 col-6 box-col-4 xl-33 img-hover hover-6" itemprop="associatedMedia" itemscope=""><a href="assets/images/g5.jpg" itemprop="contentUrl" data-size="1600x950">
                            <div><img src="assets/images/g5.jpg" itemprop="thumbnail" alt="Image description"/></div></a>
                          <figcaption itemprop="caption description">Image caption  1</figcaption>
                          <div class="but-edit">
                            <button class="btn btn-pill btn-outline-primary btn-air-primary btn-xs" type="button"><i class="fa-solid fa-pen-to-square"></i></button>
                            <button class="btn btn-pill btn-outline-secondary btn-air-secondary btn-xs" type="button"><i class="fa-solid fa-trash"></i></button>
                          </div>
                        </figure>
                        <figure class="col-md-3 col-6 box-col-4 xl-33 img-hover hover-6" itemprop="associatedMedia" itemscope=""><a href="assets/images/g6.jpg" itemprop="contentUrl" data-size="1600x950">
                            <div><img src="assets/images/g6.jpg" itemprop="thumbnail" alt="Image description"/></div></a>
                          <figcaption itemprop="caption description">Image caption  2</figcaption>
                          <div class="but-edit">
                            <button class="btn btn-pill btn-outline-primary btn-air-primary btn-xs" type="button"><i class="fa-solid fa-pen-to-square"></i></button>
                            <button class="btn btn-pill btn-outline-secondary btn-air-secondary btn-xs" type="button"><i class="fa-solid fa-trash"></i></button>
                          </div>
                        </figure>
                        <figure class="col-md-3 col-6 box-col-4 xl-33 img-hover hover-6" itemprop="associatedMedia" itemscope=""><a href="assets/images/g7.jpg" itemprop="contentUrl" data-size="1600x950">
                            <div><img src="assets/images/g7.jpg" itemprop="thumbnail" alt="Image description"/></div></a>
                          <figcaption itemprop="caption description">Image caption  3</figcaption>
                          <div class="but-edit">
                            <button class="btn btn-pill btn-outline-primary btn-air-primary btn-xs" type="button"><i class="fa-solid fa-pen-to-square"></i></button>
                            <button class="btn btn-pill btn-outline-secondary btn-air-secondary btn-xs" type="button"><i class="fa-solid fa-trash"></i></button>
                          </div>
                        </figure>
                        <figure class="col-md-3 col-6 box-col-4 xl-33 img-hover hover-6" itemprop="associatedMedia" itemscope=""><a href="assets/images/g8.jpg" itemprop="contentUrl" data-size="1600x950">
                            <div><img src="assets/images/g8.jpg
                              " itemprop="thumbnail" alt="Image description"/></div></a>
                          <figcaption itemprop="caption description">Image caption  4</figcaption>
                          <div class="but-edit">
                            <button class="btn btn-pill btn-outline-primary btn-air-primary btn-xs" type="button"><i class="fa-solid fa-pen-to-square"></i></button>
                            <button class="btn btn-pill btn-outline-secondary btn-air-secondary btn-xs" type="button"><i class="fa-solid fa-trash"></i></button>
                          </div>
                        </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div id="right-sidebar" class="settings-panel">
            <i class="settings-close fa-regular fa-circle-xmark f-20"></i>
            
          </div>

          

        </div>
        <div class="tap-top"><i class="fa-solid fa-arrow-turn-up"></i></div>
        <footer class="footer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 footer-copyright">
                <p class="mb-0">Copyright 2023 © Softa All rights reserved.</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Add Gallery Image</h6>
            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3 row">
              <label class="form-label">Upload Image</label>
              <input class="form-control" type="file"/>
            </div>
            <div class="mb-3 row">
              <label class="form-label">Add Figure Caption</label>
              <input class="form-control" type="text"/>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
            <button class="btn btn-primary" type="button">Add Image</button>
          </div>
        </div>
      </div>
    </div>
    </>
    )
  }
import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import Home from './routes/home/home.component';
import HomePage from './routes/homepage/homepages.component';
import Editor from "./routes/editor/editor";
import { pageLoad } from "./redux/actions/pageAction";
import Authentication from './routes/authentication/authentication.component';
import Shop from './routes/shop/shop.component';
import Checkout from './routes/checkout/checkout.component';
import Dashboard from './dashboard/Dashboard';
import MainMenu from './dashboard/MainMenu';
import LunchMenu from './dashboard/LunchMenu';
import LunchTiming from './dashboard/LunchTiming';
import HolidayTiming from './dashboard/HolidayTiming';
import OpeningHours from './dashboard/OpeningHours';
import OrderHistory from './dashboard/OrderHistory';
import ScheduleOrderHistory from './dashboard/ScheduleOrderHistory';
import NewBooking from './dashboard/NewBooking';
import Gallery from './dashboard/Gallery';
import OnlineSalesLog from './dashboard/OnlineSalesLog';
import DailySalesLog from './dashboard/DailySalesLog';
import WeeklySalesReport from './dashboard/WeeklySalesReport';
import MonthlySalesReport from './dashboard/MonthlySalesReport';
import AddOffers from './dashboard/AddOffers';
import MenuOffers from './dashboard/MenuOffers';
import CouponCode from './dashboard/CouponCode';
import Login from "./routes/login/Login";
function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    pageLoad()(dispatch);
  }, [dispatch]);

  return (
    <Router>
        <Route exact path="/" component={Login}></Route>
        <Route exact path="/editor/:pageId" component={Editor}></Route>
        {/* <Route index component={Home} /> */}
        <Route exact path='/shop' component={Shop} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/auth' component={Authentication} />
        <Route exact path='/checkout' component={Checkout} />
        <Route exact path='/dashboard' component={Dashboard} />
        <Route exact path='/mainmenu' component={MainMenu} />
        <Route exact path='/lunchmenu' component={LunchMenu} />
        <Route exact path='/lunchtiming' component={LunchTiming} />
        <Route exact path='/openinghours' component={OpeningHours} />
        <Route exact path='/holidaytiming' component={HolidayTiming} />
        <Route exact path='/orderhistory' component={OrderHistory} />
        <Route exact path='/scheduleorderhistory' component={ScheduleOrderHistory} />
        <Route exact path='/newbooking' component={NewBooking} />
        <Route exact path='/gallery' component={Gallery} />
        <Route exact path='/onlinesaleslog' component={OnlineSalesLog} />
        <Route exact path='/dailysaleslog' component={DailySalesLog} />
        <Route exact path='/weeklysalesreport' component={WeeklySalesReport} />
        <Route exact path='/monthlysalesreport' component={MonthlySalesReport} />
        <Route exact path='/addoffers' component={AddOffers} />
        <Route exact path='/menuoffers' component={MenuOffers} />
        <Route exact path='/couponcode' component={CouponCode} />
        {<Route exact path="/pages" component={HomePage}></Route> }
      
    </Router>
  );
}

export default App;
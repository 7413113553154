import React from 'react'

export default function 
() {
  return (
    <>
       <div className="tap-top"><i className="fa-solid fa-arrow-turn-up"></i></div>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 footer-copyright">
                <p className="mb-0">Copyright 2023 © Softa All rights reserved.</p>
              </div>
            </div>
          </div>
        </footer> 
    </>
  )
}

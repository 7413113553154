import Header from './Header';
import SideNav from './SideNav';
import Footer from './Footer';
import React, { useEffect } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
export default function AddOffers() {


  return (
  <>
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
       <div className="loader-wrapper">
        <div className="loader-box">
            <div className="loader-35"></div>
        </div>
      </div>
      <Header></Header>
      
      <div className="page-body-wrapper sidebar-icon">
        
      <SideNav></SideNav>
        <div className="page-body ecommerce-dash">
          <div className="container-fluid">
            <div className="page-header dash-breadcrumb">
              <div className="row">
                <div className="col-6">                              
                  <h3 className="f-w-600">Manage your offers</h3>
                </div>
                <div className="col-6">
                  <div className="breadcrumb-sec">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li>
                      <li className="breadcrumb-item">Dashboard</li>
                      <li className="breadcrumb-item active">Manage your offers</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row size-column">
              <div className="col-sm-12">
                <div className="card m-b-30">
                  <div className="card-header pb-0 d-block">
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-12">
                          <div className="input_fields_wrap">
                              <div className="row mb-3">
                                <div className="col-lg-3">
                                    <label className="form-label"> Select category </label>
                                    <select required="" className="form-select">
                                      <option value=""> Select category </option>
                                      <option value="52">Appetizers</option>
                                      <option value="53">Vegetarian dishes</option>
                                      <option value="54">Chicken dishes</option>
                                      <option value="55">Lamb dishes</option>
                                      <option value="56"> Fish dishes</option>
                                      <option value="57">Biriyanit </option>
                                      <option value="58">Talon tandoori</option>
                                      <option value="59">Desserts</option>
                                      <option value="60">Extras</option>
                                      <option value="61">Lasten Menu</option>
                                      <option value="62">vegan menu</option>
                                      <option value="817">Thursday 16.03</option>
                                      <option value="818">Friday 17.02</option>
                                      <option value="809">Monday 20.2</option>
                                      <option value="810">Tuesday 21.02</option>
                                      <option value="811">Wednesday 22.02</option>
                                    </select>
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label"> Select menu items </label>
                                    <select className="form-select">
                                      <option value="334">Chicken soup - kanakeitto</option>
                                      <option value="335">Bataatti-Chili soup</option>
                                      <option value="336">Veg samosa (2 kpl/2pcs)</option>
                                      <option value="337">Mixed Veg pakora</option>
                                      <option value="338">Paneer pakora</option>
                                      <option value="339">Chicken pakora</option>
                                      <option value="340">Sweet &amp; sour chaat</option>
                                      <option value="341">Paneer tikka salad</option>
                                      <option value="342">Chicken tikka salad</option>
                                      <option value="343">King prawn saslik salad </option>
                                    </select>
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Discount Type</label>
                                    <select className="form-select">
                                        <option value="">Not Applied</option>
                                        <option value="1">Percentage</option>
                                        <option value="2">Flat Amount</option>
                                    </select>
                                </div>
                                <div className="col-lg-3">
                                  <label className="form-label">Discount:</label>
                                  <input className="form-control" type="text" placeholder="Enter Discount Like 5"/>
                                </div>
                              </div>
                              <div className="row m-b-50">
                                <div className="col-lg-3">
                                  <label className="form-label">Menu Image</label>
                                  <input className="form-control" type="file"/>
                                </div>
                                <div className="col-lg-3">
                                  <button className="btn btn-primary m-t-30" type="button"> Save </button>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header pb-0 d-block">
                  </div>
                  <div className="card-body mb-0">
                    <div className="row">
                      <div className="col-lg-12">
                          <div className="input_fields_wrap">
                              <div className="row mb-3">
                                <div className="col-lg-6">
                                  <label className="form-label">Section Title English:</label>
                                  <input className="form-control mb-3" type="text" placeholder="Section Title English"/>
                                </div>
                                <div className="col-lg-6">
                                  <label className="form-label">Section title other language:</label>
                                  <input className="form-control mb-3" type="text" placeholder="Section Title  other language"/>
                                </div>
                                <div className="col-lg-6">
                                  <label className="form-label">Note English:</label>
                                  <input className="form-control" type="text" placeholder="Note english"/>
                                </div>
                                <div className="col-lg-6">
                                  <label className="form-label">Not Other Language:</label>
                                  <input className="form-control" type="text" placeholder="Not other language"/>
                                </div>
                              </div>
                              <div className="row m-b-50">
                                <div className="col-lg-6">
                                  <label className="form-label">Menu Page Note:</label>
                                  <textarea className="form-control" rows="3"></textarea>
                                </div>
                                <div className="col-lg-3">
                                  <button className="btn btn-primary m-t-30" type="button"> Update </button>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped dt-responsive nowrap table-hover" >
                      <thead>
                          <tr>
                              <th>S No</th>
                              <th>Menu</th>
                              <th>Menu Image</th>
                              <th>Action</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>1</td>
                              <td>MALAI KOFTA</td>
                              <td><img src="assets/images/d1.jpg" alt="" className="dish-img"/></td>
                              <td>
                                <button className="btn btn-pill btn-outline-primary btn-xs" type="button" title="Edit"  data-bs-toggle="modal" data-bs-target="#EditMainMenu"><i className="fa-solid fa-pen-to-square"></i></button>
                                <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Stop Selling"><i className="fa-regular fa-circle-xmark"></i></button>
                                <button className="btn btn-pill btn-outline-danger btn-xs" type="button" title="Delete"><i className="fa-solid fa-trash"></i></button>
                              </td>
                          </tr>
                          <tr>
                              <td>2</td>
                              <td>BUTTER CHICKEN</td>
                              <td><img src="assets/images/d2.jpg" alt="" className="dish-img"/></td>
                              <td>
                                <button className="btn btn-pill btn-outline-primary btn-xs" type="button" title="Edit"  data-bs-toggle="modal" data-bs-target="#EditMainMenu"><i className="fa-solid fa-pen-to-square"></i></button>
                                <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Stop Selling"><i className="fa-regular fa-circle-xmark"></i></button>
                                <button className="btn btn-pill btn-outline-danger btn-xs" type="button" title="Delete"><i className="fa-solid fa-trash"></i></button>
                              </td>
                          </tr>                     
                      </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div id="right-sidebar" className="settings-panel">
            <i className="settings-close fa-regular fa-circle-xmark f-20"></i>
            
          </div>

          

        </div>
        <div className="tap-top"><i className="fa-solid fa-arrow-turn-up"></i></div>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 footer-copyright">
                <p className="mb-0">Copyright 2023 © Softa All rights reserved.</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
    <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title">Add Gallery Image</h6>
            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="mb-3 row">
              <label className="form-label">Upload Image</label>
              <input className="form-control" type="file"/>
            </div>
            <div className="mb-3 row">
              <label className="form-label">Add Figure Caption</label>
              <input className="form-control" type="text"/>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
            <button className="btn btn-primary" type="button">Add Image</button>
          </div>
        </div>
      </div>
    </div>
    </>
    )
  }
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { API_HOST } from "../../api_utils";
import Sidebar from "../../components/editor-sidebar/editor-sidebar.component";
import TopNav from "../../components/editor-top-navigation/top-navigation.component";
import geditorConfig from "../../api_utils/geditor_config";
import PageSection from "../../components/editor-page-section/page-section.component";
import Navigation from "../navigation/navigation.component";
import { Modal, Button, Form } from "react-bootstrap";
import Pagination from "../../components/editor-modal-pagination/pagination.component";
import TemplateCard from "../../components/editor-modal-card/template-card.component";
import TemplateSiebar from "../../components/editor-modal-sidebar/template-sidebar.component";
import "../../styles/main.scss";
const Editor = () => {
  const [editor, setEditor] = useState(null);
  const [assets, setAssets] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryid, setCategoryid] = useState('');
  const [templateId, setTemplatesid] = useState([]);
  const [flag, setFlag] = useState(0);
  const { pageId } = useParams();
  const { pageStore } = useSelector((state) => state);
  const { pages } = pageStore;
  const [show, setShow] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [filteredTemplates, setFilterTemplates] = useState(templates);
  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);
  const [pageCount, setpageCount] = useState(0);
  const [pageNumber, setpageNumber] = useState(1);
  const [totalRecords, settotalRecords] = useState(0);
  const [totalRecordFilter, settotalRecordsFilter] = useState(0);
  const [keywords, setkeywords] = useState("");

  useEffect(() => {
    setShow(false);
    setpageNumber(1);
    setkeywords("");
    async function getAllAssets() {
      try {
        const response = await axios.get(`${API_HOST}assets`);
        setAssets(response.data);
      } catch (error) {
        setAssets(error.message);
      }
    }
    getAllAssets();
  }, []);
  const editTemplateById = (data) => {
    setTemplatesid(data);
    setFlag(1);
    setShow(false);
  };
  const getCategory = async (data) => {
    setpageNumber(1);
    try {
      const response = await axios.get(
        `${API_HOST}get_templates?page_no=1&keyword=${keywords}&category=${data}`
      );
      setTemplates(response.data.paginationDataList);
      setpageCount(response.data.pagination.total_pages);
      settotalRecords(response.data.paginationDataListCount);
      
    } catch (error) {
      setTemplates(error.message);
    }
    setCategoryid(data);    
  };
  useEffect(() => {
    async function getAllCategory() {
      try {
        const response = await axios.get(
          `${API_HOST}get_template_category`
        );
        setCategory(response.data.paginationDataList);
        settotalRecordsFilter(response.data.paginationDataListCount);
      } catch (error) {
        setCategory(error.message);
      }
    }
    getAllCategory();
  }, [templates]);
  useEffect(() => {
    async function getAllTemplates() {
      try {
        const response = await axios.get(
          `${API_HOST}get_templates?page_no=${pageNumber}&keyword=${keywords}&category=${categoryid}`
        );
        setTemplates(response.data.paginationDataList);
        setpageCount(response.data.pagination.total_pages);
        settotalRecords(response.data.paginationDataListCount);
      } catch (error) {
        setTemplates(error.message);
      }
    }
    getAllTemplates();
  }, []);

  useEffect(() => {
    try {
      const editor = geditorConfig(assets, pageId, templateId, flag);
      setEditor(editor);
    //  editor.DomComponents.clear(); 
    //  editor.CssComposer.clear();  
    //  editor.UndoManager.clear(); 
    //  editor.setComponents([{}, {}]) 
    //  editor.setStyle([{}, {}]) 
    //  editor.refresh();
    } catch (err) {
      console.log(err);
    }
  }, [assets, pageId, templateId]);

  useEffect(() => {
    const newFilteredMonsters = templates.filter((templates) => {
      return templates.label.toLocaleLowerCase().includes(searchField);
    });
    setFilterTemplates(newFilteredMonsters);
  }, [templates, searchField]);
  const fetchKeywords = async (keywords1) => {
    try {
      const response = await axios.get(
        `${API_HOST}get_templates?page_no=${pageNumber}&keyword=${keywords1}&category=${categoryid}`
      );
      setTemplates(response.data.paginationDataList);
      setpageCount(response.data.pagination.total_pages);
      settotalRecords(response.data.paginationDataListCount);
      setkeywords(keywords1);
    } catch (error) {
      setTemplates(error.message);
    }
    //  return data;
  };
  const onSearchChange = (event) => {
    const searchFieldString = event.target.value.toLocaleLowerCase();
    //  setSearchField(searchFieldString);
    setpageNumber(1);
    fetchKeywords(searchFieldString);
  };
  const fetchComments = async (currentPage) => {
    try {
      const response = await axios.get(
        `${API_HOST}get_templates?page_no=${currentPage}&keyword=${keywords}&category=${categoryid}`
      );
      console.log(response.data);
      setTemplates(response.data.paginationDataList);
      setpageCount(response.data.pagination.total_pages);
      settotalRecords(response.data.paginationDataListCount);
      setpageNumber(currentPage);
    } catch (error) {
      setTemplates(error.message);
    }
    //  return data;
  };
  const handlePageClick = async (data) => {
    console.log(data.selected);

    let currentPage = data.selected + 1;

    const commentsFormServer = await fetchComments(currentPage);

    //setTemplates(commentsFormServer);
    // scroll to the top
    //window.scrollTo(0, 0)
  };
  return (
    <>
   
      <div className="App">
        <div
          id="navbar"
          className="sidenav d-flex flex-column overflow-scroll position-fixed"
        >
          <nav className="navbar navbar-light">
            {
              <div className="container-fluid">
                <Button
                  className="modalChosetemplate"
                  variant="primary"
                  onClick={handleShow}
                >
                  Choose Template
                </Button>
              </div>
            }
          </nav>
          <PageSection pages={pages} />
          <Sidebar />
        </div>
        <div
          className="main-content position-relative w-85 start-15"
          id="main-content"
        >
          <TopNav />
          <div id="editor"></div>
        </div>
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
        >
          <Modal.Header>
            <Modal.Title></Modal.Title>
            <div className="right-button"><button className="closebutton" onClick={handleHide}>X</button></div>
          </Modal.Header>
          <Modal.Body>
          <section className="section">
				  <div className="container">
					  	<div className="row">
					  <TemplateSiebar category={category} categoryid={categoryid} totalRecordFilter={totalRecordFilter} getCategory={getCategory} onSearchChange={onSearchChange} keywords={keywords} />
            <TemplateCard filteredTemplates={filteredTemplates} editTemplateById={editTemplateById}/> 
            <div className="col-lg-3"></div>
  <div className="col-lg-9">
            <Pagination handlePageClick={handlePageClick} pageNumber={pageNumber} pageCount={pageCount} />
						</div>
            </div>
				  </div>
			  </section>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Editor;

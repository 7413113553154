import React,{useEffect} from 'react'
import { Link } from 'react-router-dom';
import $ from 'jquery';
import jQuery from 'jquery';
export default function SideNav() {
  useEffect(() => {
    // $(document).ready(function() {
    //     $('#example').DataTable( {
    //         select: true
    //     } );
    // } );
    // $(document).ready(function() {
    //     $('#example1').DataTable( {
    //         select: true
    //     } );
    // } );
    $(".nav-settings").on("click", function() {
      $("#right-sidebar").toggleClass("open");
    });
    $(".settings-close").on("click", function() {
      $("#right-sidebar,#theme-settings").removeClass("open");
    });
    $(".toggle-nav").click(function () {
      $('.nav-menu').css("left", "0px");
    });
    $(".mobile-back").click(function () {
        $('.nav-menu').css("left", "-410px");
    });

    $(".page-wrapper").attr("class", "page-wrapper "+localStorage.getItem('page-wrapper'));
    $(".page-body-wrapper").attr("class", "page-body-wrapper "+localStorage.getItem('page-body-wrapper'));

    if (localStorage.getItem("page-wrapper") === null) {
        $(".page-wrapper").addClass("compact-wrapper");
        $(".page-body-wrapper").addClass("sidebar-icon");
    }

    if($('#pageWrapper').hasClass('compact-wrapper')){

      jQuery('.menu-title').append('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
        jQuery('.menu-title').click(function () {
            jQuery('.menu-title').removeClass('active').find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
            jQuery('.menu-content').slideUp('normal');
            if (jQuery(this).next().is(':hidden') == true) {
                jQuery(this).addClass('active');
                jQuery(this).find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-down"></i></div>');
                jQuery(this).next().slideDown('normal');
            } else {
                jQuery(this).find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
            }
        });
        jQuery('.menu-content').hide();

        
        jQuery('.submenu-title').append('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
        jQuery('.submenu-title').click(function () {
            jQuery('.submenu-title').removeClass('active').find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
            jQuery('.submenu-content').slideUp('normal');
            if (jQuery(this).next().is(':hidden') == true) {
                jQuery(this).addClass('active');
                jQuery(this).find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-down"></i></div>');
                jQuery(this).next().slideDown('normal');
            } else {
                jQuery(this).find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
            }
        });
        jQuery('.submenu-content').hide();

  } else if ($('#pageWrapper').hasClass('horizontal-wrapper')) {
      var contentwidth = jQuery(window).width();
      if ((contentwidth) < '992') {
          $('#pageWrapper').removeClass('horizontal-wrapper').addClass('compact-wrapper');
          $('.page-body-wrapper').removeClass('horizontal-menu').addClass('sidebar-icon');
          jQuery('.submenu-title').append('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
        jQuery('.submenu-title').click(function () {
            jQuery('.submenu-title').removeClass('active');
            jQuery('.submenu-title').find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
            jQuery('.submenu-content').slideUp('normal');
            if (jQuery(this).next().is(':hidden') == true) {
                jQuery(this).addClass('active');
                jQuery(this).find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-down"></i></div>');
                jQuery(this).next().slideDown('normal');
            } else {
                jQuery(this).find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
            }
        });
        jQuery('.submenu-content').hide();

        jQuery('.menu-title').append('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
        jQuery('.menu-title').click(function () {
            jQuery('.menu-title').removeClass('active');
            jQuery('.menu-title').find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
            jQuery('.menu-content').slideUp('normal');
            if (jQuery(this).next().is(':hidden') == true) {
                jQuery(this).addClass('active');
                jQuery(this).find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-down"></i></div>');
                jQuery(this).next().slideDown('normal');
            } else {
                jQuery(this).find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-right"></i></div>');
            }
        });
        jQuery('.menu-content').hide();
      }

  }
  // toggle sidebar
  let $nav =""; let $header=""; let $toggle_nav_top =""; let $this ="";
  $nav = $('.main-nav');
  $header = $('.page-main-header');
  $toggle_nav_top = $('#sidebar-toggle');
  $toggle_nav_top.click(function() {
    $this = $(this);
    $nav = $('.main-nav');
    $nav.toggleClass('close_icon');
    $header.toggleClass('close_icon');
  });

  $( window ).resize(function() {
    $nav = $('.main-nav');
    $header = $('.page-main-header');
    $toggle_nav_top = $('#sidebar-toggle');
    $toggle_nav_top.click(function() {
      $this = $(this);
      $nav = $('.main-nav');
      $nav.toggleClass('close_icon');
      $header.toggleClass('close_icon');
    });
  });
  
  let $body_part_side ="";
  $body_part_side = $('.body-part');
  $body_part_side.click(function(){
    $toggle_nav_top.attr('checked', false);
    $nav.addClass('close_icon');
    $header.addClass('close_icon');
  });
  
  //    responsive sidebar
  var $window = $(window);
  var widthwindow = $window.width();
  (function($) {
  "use strict";
  if(widthwindow <= 991) {
      $toggle_nav_top.attr('checked', false);
      $nav.addClass("close_icon");
      $header.addClass("close_icon");
  }
  })(jQuery);
  $( window ).resize(function() {
  var widthwindaw = $window.width();
  if(widthwindaw <= 991){
      $toggle_nav_top.attr('checked', false);
      $nav.addClass("close_icon");
      $header.addClass("close_icon");
  }else{
      $toggle_nav_top.attr('checked', true);
      $nav.removeClass("close_icon");
      $header.removeClass("close_icon");
  }
  });
  
// horizontal arrowss
var view = $("#mainnav");
var move = "500px";
var leftsideLimit = -500

// var Windowwidth = jQuery(window).width();
// get wrapper width
var getMenuWrapperSize = function () {
    return $('.sidebar-wrapper').innerWidth();
}
var menuWrapperSize = getMenuWrapperSize();

if ((menuWrapperSize) >= '1660') {
    var sliderLimit = -3000
    
} else if ((menuWrapperSize) >= '1440') {
    var sliderLimit = -3600
} else {
    var sliderLimit = -4200
}

$("#left-arrow").addClass("disabled");

$("#right-arrow").click(function(){
    var currentPosition = parseInt(view.css("left"));
    if (currentPosition >= sliderLimit) {
        $("#left-arrow").removeClass("disabled");
        view.stop(false,true).animate({left:"-="+move},{ duration: 400})
        if (currentPosition == sliderLimit) {
            $(this).addClass("disabled");
        }
    }
});

$("#left-arrow").click(function(){
    var currentPosition = parseInt(view.css("left"));
    if (currentPosition < 0) {
        view.stop(false,true).animate({left:"+="+move},{ duration: 400})
        $("#right-arrow").removeClass("disabled");
        $("#left-arrow").removeClass("disabled");
        if (currentPosition >= leftsideLimit) {
            $(this).addClass("disabled");
        }
    }

});

// page active
    $( ".main-navbar" ).find( "a" ).removeClass("active");
    $( ".main-navbar" ).find( "li" ).removeClass("active");

    var current = window.location.pathname
    $(".main-navbar ul>li a").filter(function() {

        var link = $(this).attr("href");
        if(link){
            if (current.indexOf(link) != -1) {
                $(this).parents().children('a').addClass('active');
                $(this).parents().parents().children('ul').css('display', 'block');
                $(this).addClass('active');
                $(this).parent().parent().parent().children('a').find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-down"></i></div>');
                $(this).parent().parent().parent().parent().parent().children('a').find('div').replaceWith('<div className="according-menu"><i className="fa fa-caret-down"></i></div>');
                return false;
            }
        }
    });
    (function($) {
      "use strict";
      $(".mobile-toggle").click(function(){
          $(".nav-menus").toggleClass("open");
      });
      $(".mobile-toggle-left").click(function(){
          $(".left-header").toggleClass("open");
      });
      $(".mobile-search").click(function(){
         $(".form-control-plaintext").toggleClass("open");
     });
      $(".bookmark-search").click(function(){
          $(".form-control-search").toggleClass("open");
      })
      $(".filter-toggle").click(function(){
          $(".product-sidebar").toggleClass("open");
      });
      $(".toggle-data").click(function(){
          $(".product-wrapper").toggleClass("sidebaron");
      });
      $(".form-control-search").keyup(function(e){
          if(e.target.value) {
              $(".page-wrapper.horizontal-wrapper").addClass("offcanvas-bookmark");
          } else {
              $(".page-wrapper.horizontal-wrapper").removeClass("offcanvas-bookmark");
          }
      });
  
      /*=====================
        02. Background Image js
        ==========================*/
      $(".bg-center").parent().addClass('b-center');
      $(".bg-img-cover").parent().addClass('bg-size');
      $('.bg-img-cover').each(function () {
          var el = $(this),
              src = el.attr('src'),
              parent = el.parent();
          parent.css({
              'background-image': 'url(' + src + ')',
              'background-size': 'cover',
              'background-position': 'center',
              'display': 'block'
          });
          el.hide();
      });
  })(jQuery);
  
  $('.loader-wrapper').fadeOut('slow', function() {
      $(this).remove();
  });
  
  
  $(window).on('scroll', function() {
      if ($(this).scrollTop() > 600) {
          $('.tap-top').fadeIn();
      } else {
          $('.tap-top').fadeOut();
      }
  });
  
  $('.tap-top').click( function() {
      $("html, body").animate({
          scrollTop: 0
      }, 600);
      return false;
  });
  
$("#viewfullscreen").on("click", function(){
  if ((document.fullScreenElement && document.fullScreenElement !== null) ||
  (!document.mozFullScreen && !document.webkitIsFullScreen)) {
  if (document.documentElement.requestFullScreen) {
      document.documentElement.requestFullScreen();
  } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
  } else if (document.documentElement.webkitRequestFullScreen) {
      document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
  }
} else {
  if (document.cancelFullScreen) {
      document.cancelFullScreen();
  } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
  }
}
});
  (function($, window, document, undefined) {
      "use strict";
      var $ripple = $(".js-ripple");
      $ripple.on("click.ui.ripple", function(e) {
          var $this = $(this);
          var $offset = $this.parent().offset();
          var $circle = $this.find(".c-ripple__circle");
          var x = e.pageX - $offset.left;
          var y = e.pageY - $offset.top;
          $circle.css({
              top: y + "px",
              left: x + "px"
          });
          $this.addClass("is-active");
      });
      $ripple.on(
          "animationend webkitAnimationEnd oanimationend MSAnimationEnd",
          function(e) {
              $(this).removeClass("is-active");
          });
  })(jQuery, window, document);
  
  
  // active link
  $(".chat-menu-icons .toogle-bar").click(function(){
      $(".chat-menu").toggleClass("show");
  });
  
  $(".nav-settings").on("click", function() {
    $("#right-sidebar").toggleClass("open");
  });
  $(".settings-close").on("click", function() {
    $("#right-sidebar,#theme-settings").removeClass("open");
  });
  
  //landing header //
  $(".toggle-menu").click(function(){
      $('.landing-menu').toggleClass('open');
  });   
  $(".menu-back").click(function(){
      $('.landing-menu').toggleClass('open');
  });  
  
  
  $('.product-size ul li ').on('click', function(e) {
      $(".product-size ul li ").removeClass("active");
      $(this).addClass("active");
  });
  
  $(".md-sidebar-toggle").click(function(){
      $('.md-sidebar-aside').toggleClass('open');
  });
  
  $('.media-size-email svg').on('click', function (e) {
      $(this).toggleClass("like");
  });

  });
 
  return (
    <>
        <header className="main-nav">
        <div className="logo-wrapper"><a href="index.html"><img className="img-fluid" src="assets/images/logo-m.png" alt=""/></a></div>
          <div className="logo-icon-wrapper"><a href="index.html"><img className="img-fluid" src="assets/images/logo-m.png" alt=""/></a></div>
          <div className="morden-logo"><a href="index.html"><img className="img-fluid" src="assets/images/logo-m.png" alt=""/></a></div>
          <nav>
            <div className="main-navbar">
              <div className="left-arrow" id="left-arrow"><i data-feather="arrow-left"></i></div>
              <div id="mainnav">
                <ul className="nav-menu custom-scrollbar">
                  <li className="back-btn">
                    <div className="mobile-back text-end"><span>Back</span><i className="fa fa-angle-right ps-2" aria-hidden="true"></i></div>
                  </li>
                  <li><Link className='nav-link menu-title link-nav' to='/dashboard'>
                  <i className="bfr fa-solid fa-grid-2"></i><span>Dashboard</span>
            </Link>
                  </li>
                  <li><Link className='nav-link menu-title link-nav' to='/pages'>
                  <i className="bfr fa-solid fa-book"></i><span>Create Pages</span>
            </Link>
                  </li>
                  <li className="dropdown"><a className="nav-link menu-title" href="javascript:void(0)"><i className="bfr fa-light fa-square-list"></i><span>All Menu</span></a>
                    <ul className="nav-submenu menu-content">
                      
                      <li><Link className='nav-link' to='/mainmenu'>
                      Main Menu 
            </Link></li>
                      <li><Link className='nav-link' to='/lunchmenu'>
                      Lunch Menu
            </Link></li>
                    </ul>
                  </li>
                  <li className="dropdown"><a className="nav-link menu-title" href="javascript:void(0)"><i className="bfr fa-regular fa-clock"></i><span>Set Timing</span></a>
                    <ul className="nav-submenu menu-content">
                      <li><Link className='nav-link' to='/lunchtiming'>
                      Lunch Timing
            </Link></li>
                      <li><Link className='nav-link' to='/openinghours'>
                      Opening Hours
            </Link></li>
                      <li><Link className='nav-link' to='/holidaytiming'>
                      Holiday Timing
            </Link></li>
                    </ul>
                  </li>
                  <li className="dropdown"><a className="nav-link menu-title" href="javascript:void(0)"><i className="bfr fa-solid fa-bag-shopping"></i><span>Order History</span></a>
                    <ul className="nav-submenu menu-content">
                      <li><Link className='nav-link' to='/orderhistory'>
                      Online Orders
            </Link></li>
                      <li><Link className='nav-link' to='/scheduleorderhistory'>
                      Schedule Orders
            </Link></li>
                    </ul>
                  </li>
                  <li><Link className='nav-link menu-title link-nav' to='/newbooking'>
                  <i className="bfr fa-sharp fa-regular fa-table-picnic"></i><span>Table Booking</span>
            </Link></li>
                  <li><Link className='nav-link menu-title link-nav' to='/gallery'>
                  <i className="bfr fa-solid fa-gallery-thumbnails"></i><span>Gallery</span>
            </Link></li>
            <li className="dropdown"><a className="nav-link menu-title" href="javascript:void(0)"><i className="bfr fa-solid fa-chart-pie"> </i> <span>Sales Log</span></a>
                    <ul className="nav-submenu menu-content">
                      <li><Link className='nav-link' to='/onlinesaleslog'>
                      Online Sales Log
            </Link></li>
            <li><Link className='nav-link' to='/dailysaleslog'>
                      Daily Sales Log
            </Link></li>
                    </ul>
                  </li>
                  <li className="dropdown"><a className="nav-link menu-title" href="javascript:void(0)"><i className="bfr fa-solid fa-chart-line-up"> </i> <span>Sales Report</span></a>
                    <ul className="nav-submenu menu-content">
                    <li><Link className='nav-link' to='/WeeklySalesReport'>
                    Weekly Report
            </Link></li>
            <li><Link className='nav-link' to='/monthlysalesreport'>
            Monthly Report
            </Link></li>
                     
                    </ul>
                  </li>
                  <li className="dropdown"><a className="nav-link menu-title" href="javascript:void(0)"><i className="bfr fa-sharp fa-regular fa-money-bills"> </i> <span>Manage Offers</span></a>
                    <ul className="nav-submenu menu-content">
                    <li><Link className='nav-link' to='/addoffers'>
                    Add Offers
            </Link></li>
            <li><Link className='nav-link' to='/menuoffers'>
            Menu Offers
            </Link></li>
            <li><Link className='nav-link' to='/couponcode'>
            Coupon Code
            </Link></li>
                     
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="right-arrow" id="right-arrow"><i data-feather="arrow-right"></i></div>
            </div>
          </nav>
        </header>
    </>
  )
}

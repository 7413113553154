import Header from './Header';
import SideNav from './SideNav';
import Footer from './Footer';
import React, { useEffect } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
export default function WeeklySalesReport() {


  return (
  <>
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
       <div className="loader-wrapper">
        <div className="loader-box">
            <div className="loader-35"></div>
        </div>
      </div>
      <Header></Header>
      
      <div className="page-body-wrapper sidebar-icon">
        
      <SideNav></SideNav>
        
        <div className="page-body ecommerce-dash">
          <div className="container-fluid">
            <div className="page-header dash-breadcrumb">
              <div className="row">
                <div className="col-6">                              
                  <h3 className="f-w-600">Weekly Invoice Report</h3>
                </div>
                <div className="col-6">
                  <div className="breadcrumb-sec">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li>
                      <li className="breadcrumb-item">Dashboard</li>
                      <li className="breadcrumb-item active">Weekly Invoice Report</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row size-column">
              <div className="col-md-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-4">
                        <label className="form-label">From</label>
                        <input className="form-control digits" type="date"/>
                      </div>
                      <div className="col-sm-4">
                        <label className="form-label">To</label>
                        <input className="form-control digits" type="date"/>
                      </div>
                      <div className="col-sm-4">
                        <button className="btn btn-primary m-t-30" type="submit">Apply</button>
                        <button className="btn btn-danger m-t-30" type="cancel">Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header pb-0 d-block">
                    <div className="row">
                      <div className="col-md-6">
                        <h5>Weekly Invoice Report</h5>
                      </div>
                      <div className="col-md-6 text-end">
                        <div className="btn-group">
                          <button className="btn btn-primary" title="Copy"><i className="fa-solid fa-copy"></i></button>
                          <button className="btn btn-secondary" title="Excel"><i className="fa-solid fa-file-spreadsheet"></i></button>
                          <button className="btn btn-success" title="PDF"><i className="fa-solid fa-file-pdf"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table id="example" className="table table-striped dt-responsive nowrap table-hover">
                        <thead>
                            <tr>
                                <th>Si No</th>
                                <th>Invoice ID</th>
                                <th>Total Amount</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Invoice Date</th>
                                <th>Status</th>
                                <th>Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td>1</td>
                              <td>1197</td>
                              <td>48.30 €</td>
                              <td>2023-02-06</td>
                              <td>2023-02-12</td>
                              <td>2023-02-13</td>
                              <td><p className="badge badge-warning">Pending</p></td>
                              <td><button className="btn btn-primary" type="submit">View Invoice</button></td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>1181</td>
                              <td>39.50  €</td>
                              <td>2023-01-30</td>
                              <td>2023-02-05</td>
                              <td>2023-02-06</td>
                              <td><p className="badge badge-success">Paid</p></td>
                              <td><button className="btn btn-primary" type="submit">View Invoice</button></td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>1158</td>
                              <td>69.70 €</td>
                              <td>2023-01-23</td>
                              <td>2023-01-29</td>
                              <td>2023-01-30</td>
                              <td><p className="badge badge-success">Paid</p></td>
                              <td><button className="btn btn-primary" type="submit">View Invoice</button></td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div id="right-sidebar" className="settings-panel">
            <i className="settings-close fa-regular fa-circle-xmark f-20"></i>
            
          </div>

          

        </div>
        <div className="tap-top"><i className="fa-solid fa-arrow-turn-up"></i></div>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 footer-copyright">
                <p className="mb-0">Copyright 2023 © Softa All rights reserved.</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
    <div className="modal fade" id="OrderDetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title">ORDER DETAILS</h6>
            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="table-responsive mb-3">
              <table className="table table-striped table-bordernone">
                <tbody>
                  <tr>
                    <td><strong>Order ID : RES3608111655576293</strong></td>
                    <td><strong>Customer Name : Siiri Westerberg</strong></td>
                  </tr>
                  <tr>                    
                    <td><strong>Contact No : 413196233</strong></td>
                    <td><strong>Date & Time : 2022-06-18, 21:18</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="text-danger mb-3"><strong>Note:- </strong></p>
            <div className="table-responsive">
              <table className="table table-bordered table-striped">
                  <thead>
                      <tr>
                          <th className="font">#</th>
                          <th className="font">Category</th>
                          <th className="font">Product</th>
                          <th className="font">Price</th>
                          <th className="font">Quantity</th>
                          <th className="font">Total</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>VEGETARIAN DISHES	</td>
                      <td>MALAI KOFTA</td>
                      <td>13.90€</td>
                      <td>1</td>
                      <td>13.90€</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>CHICKEN DISHES	</td>
                      <td>BUTTER CHICKEN</td>
                      <td>15.90€</td>
                      <td>1</td>
                      <td>15.90€</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>SIDE ORDER</td>
                      <td>RAITA</td>
                      <td>2.50€</td>
                      <td>1</td>
                      <td>2.50€</td>
                    </tr>
                    <tr>
                      <td colspan="5" className="total-amount p-2">
                        <p className="m-0"><span className="f-w-600">In Total :</span></p>
                      </td>
                      <td className="p-2"><span>32.30 €</span></td>
                    </tr>
                    <tr>
                      <td colspan="5" className="total-amount p-2">
                        <p className="m-0"><span className="f-w-600">Delivery Charges :</span></p>
                      </td>
                      <td className="p-2"><span>+0.00 €</span></td>
                    </tr>
                    <tr>
                      <td colspan="5" className="total-amount p-2">
                        <p className="m-0"><span className="f-w-600">Discount :</span></p>
                      </td>
                      <td className="p-2"><span>0%</span></td>
                    </tr>
                    <tr>
                      <td colspan="5" className="total-amount p-2">
                        <p className="m-0"><span className="f-w-600">Redeemed Gift Card :</span></p>
                      </td>
                      <td className="p-2"><span>-0.00 €</span></td>
                    </tr>
                    <tr>
                      <td colspan="5" className="total-amount p-2">
                        <p className="m-0"><span className="f-w-600">Payable Amount <small>All (14% included): 4.52 €</small> :</span></p>
                      </td>
                      <td className="p-2"><span>32.30 €</span></td>
                    </tr>
                  </tbody>
              </table>
          </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" type="button" data-bs-dismiss="modal">Cancel</button>
            <button className="btn btn-primary" type="button">Submit</button>
          </div>
        </div>
      </div>
    </div>
    </>
    )
  }
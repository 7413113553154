
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_HOST } from "../../api_utils";
import { useSelector, useDispatch } from "react-redux";
import { createPage } from "../../redux/actions/pageAction";
import Navigation from '../../routes/navigation/navigation.component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { Modal, Button, Form } from "react-bootstrap";
import Pagination from "../../components/editor-modal-pagination/pagination.component";
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee,faEye,faPencil, faTrash } from '@fortawesome/free-solid-svg-icons'
import '../../styles/main.scss';
import "./homepages.styles.scss";
import Header from '../../dashboard/Header';
import SideNav from '../../dashboard/SideNav';
import Footer from '../../dashboard/Footer';
import { terminate } from "firebase/firestore";
library.add(fab, faCheckSquare, faCoffee,faEye,faPencil,faTrash)
const HomePage = () => {
  const [name, setName] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [srchtml,setsrchtml]=useState("");
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);
  const { pageStore } = useSelector((state) => state);
  const [pages, setPages] = useState([""]);
  const [pageCount, setpageCount] = useState(0);
  const [pageNumber, setpageNumber] = useState(1);
  useEffect(() => {
    async function getAllPages() {
      try {
        const response = await axios.get(`${API_HOST}get_pages?page_no=${pageNumber}`);
        console.log(response);
        setPages(response.data.paginationDataList);
        setpageCount(response.data.pagination.total_pages);
      } catch (error) {
        setPages(error.message);
      }
    }
    getAllPages();
  }, [pageNumber]);
  const handleSubmit = async () => {
    console.log(name);
    if (!name) {
      setIsValid(false);
      return;
    }
   await createPage(name)(dispatch);
    clearSubmit();
    getAllPages();
  };
  const deletePageById = async (data) => {
    try {
      const response = await axios.get(
        `${API_HOST}delete_page_by_id/${data}`
      ); 
      getAllPages();
    } catch (error) {
      getAllPages();
    }
   
  };
  const getAllPages= async () => {
    try {
      const response = await axios.get(`${API_HOST}get_pages?page_no=${pageNumber}`);
      console.log(response);
      setPages(response.data.paginationDataList);
      setpageCount(response.data.pagination.total_pages);
    } catch (error) {
      setpageNumber(1); 
     
    }
  }
  const clearSubmit = async () => {
    setName('');
  };
  const templatePreview = async (data) => {
   
    let d=JSON.parse(data);
    let total="<style>"+d.css+"</style>"+d.html;
    console.log(data);
 console.log(d.html);
 setsrchtml(total);
 handleShow();
  };
  // useEffect(() => {
  //   window.location.reload(false);
  // }, []);
  const fetchComments = async (currentPage) => {
    try {
      const response = await axios.get(
        `${API_HOST}get_pages?page_no=${currentPage}`
      );
      console.log(response.data);
      setPages(response.data.paginationDataList);
      setpageCount(response.data.pagination.total_pages);
      // settotalRecords(response.data.paginationDataListCount);
      setpageNumber(currentPage);
    } catch (error) {
      setPages(error.message);
    }
    //  return data;
  };
  const handlePageClick = async (data) => {
    console.log(data.selected);

    let currentPage = data.selected + 1;

    const commentsFormServer = await fetchComments(currentPage);

    //setTemplates(commentsFormServer);
    // scroll to the top
    //window.scrollTo(0, 0)
  };
  return (
    <>
  <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <div className="loader-wrapper">
        <div className="loader-box">
            <div className="loader-35"></div>
        </div>
      </div>
      <Header></Header>
      <div className="page-body-wrapper sidebar-icon">
        <SideNav></SideNav>
      
       </div>
     </div>
    <div className="container homepage">
   
      <div className="row">
       
        <div className="col-12 mt-5">
          <form id="create-page">
            <div className="modal-header">
              {/* <h5 className="modal-title" id="addPageModalLabel">
                Create Page
              </h5> */}
            </div>
            <div className="modal-body">
              <div className="col-auto">
                <label htmlFor="name" className="form-label">
                  Create New Page
                </label>
                <input
                  type="text"
                  className={`form-control form-control-sm ${
                    isValid ? "" : "is-invalid"
                  }`}
                  id="name"
                  name="name"
                  placeholder="Enter Page Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoComplete="off"
                />
                {!isValid && (
                  <div className="invalid-feedback">
                    Please provide a valid name.
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={clearSubmit}
              >
                Clear
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={handleSubmit}
              >
                Create
              </button>
            </div>
          </form>
        </div>
        <div className="col-12 my-2"> 
              {/* <label htmlFor="name" className="form-label">
              Page List
                </label> */}
          {/* <table className="table table-bordered table-hover">
            <thead>
              <tr>
               
                <td>Name</td>
                <td>Slug</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {pages.length > 0
                ? pages.map((page) => (
                    <tr key={page._id+1}>
                    
                      <td>{page.name}</td>
                      <td>{page.slug}</td>
                      <td>
                        <Link title="Edit"  to={`/editor/${page._id}`} > <FontAwesomeIcon icon={["fas", "pencil"]} pull="left" border   /></Link>
                        <a title="Delete" onClick={() => deletePageById(page._id)}><FontAwesomeIcon icon={["fas", "trash"]} pull="left" border   /></a>
                        <a title="Preview" onClick={() => templatePreview(page.content)}><FontAwesomeIcon icon={["fas", "eye"]} pull="left" border   /></a>
                      </td>
                    </tr>
                  ))
                : <tr><td>Pages Not Found</td></tr>}
            </tbody>
          </table> */}
        </div>
        <div className="page-body ecommerce-dash">
       
          <div className="container-fluid">
         
            <div className="row size-column">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header pb-0 d-block">
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table id="example" className="table table-striped dt-responsive nowrap table-hover" >
                        <thead>
                            <tr>
                                <th>S No</th>
                                <th>Name</th>
                                <th>Slug</th>
                                {/* <th>Product Name</th>
                                <th>Description</th>
                                <th>Cate</th>
                                <th>Spicy</th>
                                <th>L/G/V</th>
                                <th>Price</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {pages.length > 0
                ? pages.map((page,index) => (
                            <tr>
                              <td>{index+1}</td>
                                <td>{page.name}</td>
                                <td>{page.slug}</td>
                                {/* <td>Aloo Gobi</td>
                                <td className="dish-desc">Potato and cauliflower curry in masala sauce</td>
                                <td>Veg</td>
                                <td><img src="assets/images/chilly1.png" alt="" className="spicy"/></td>
                                <td>L G V</td>
                                <td>€13.50</td> */}
                                <td>
                                <Link title="Edit"  to={`/editor/${page._id}`} > <FontAwesomeIcon icon={["fas", "pencil"]} pull="left" border   /></Link>
                                  {/* <button className="btn btn-pill btn-outline-primary btn-xs" type="button" title="Edit"  data-bs-toggle="modal" data-bs-target="#EditMainMenu"><i className="fa-solid fa-pen-to-square"><Link title="Edit"  to={`/editor/${page._id}`} > </Link></i></button> */}
                                  <button className="btn btn-pill btn-outline-success btn-xs" onClick={() => templatePreview(page.content)}  type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-danger btn-xs" onClick={() => deletePageById(page._id)} type="button" title="Delete"><i className="fa-solid fa-trash"></i></button>
                                </td>
                            </tr>
                            ))
                            : <tr><td>Pages Not Found</td></tr>}
                        </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="right-sidebar" className="settings-panel">
            <i className="settings-close fa-regular fa-circle-xmark f-20"></i>
          </div>
        </div>
        <Pagination handlePageClick={handlePageClick} pageNumber={pageNumber} pageCount={pageCount} />
      </div>
      <Modal
          size="sm-down"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          fullscreen={true}
          show={show}
        >
          <Modal.Header>
            <Modal.Title></Modal.Title>
            <div ><button className="closebutton" onClick={handleHide}>X</button></div>
          </Modal.Header>
          <Modal.Body>
          <iframe  srcDoc={srchtml} width="100%" height="700"></iframe>
          </Modal.Body>
        </Modal>
    </div>
    
    </>
  );
};

export default HomePage;

import Header from './Header';
import SideNav from './SideNav';
import Footer from './Footer';
import React, { useEffect } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
export default function NewBooking() {


  return (
  <>
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
       <div className="loader-wrapper">
        <div className="loader-box">
            <div className="loader-35"></div>
        </div>
      </div>
      <Header></Header>
      
      <div className="page-body-wrapper sidebar-icon">
        
      <SideNav></SideNav>
        <div className="page-body ecommerce-dash">
          <div className="container-fluid">
            <div className="page-header dash-breadcrumb">
              <div className="row">
                <div className="col-4">                              
                  <h3 className="f-w-600">Table Booking Request</h3>
                </div>
                <div className="col-md-4 text-center">
                  <div className="btn-group">
                    <button className="btn btn-primary" title="Copy"><i className="fa-solid fa-copy"></i></button>
                    <button className="btn btn-secondary" title="Excel"><i className="fa-solid fa-file-spreadsheet"></i></button>
                    <button className="btn btn-success" title="PDF"><i className="fa-solid fa-file-pdf"></i></button>
                    <div className="btn-group" role="group">
                      <button className="btn btn-light dropdown-toggle" id="btnGroupDrop1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Filter</button>
                      <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                        <a className="dropdown-item" href="#">Accepted</a>
                        <a className="dropdown-item" href="#">Cancelled</a>
                        <a className="dropdown-item" href="#">Pending</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="breadcrumb-sec">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li>
                      <li className="breadcrumb-item">Dashboard</li>
                      <li className="breadcrumb-item active">Table Booking Request</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row size-column">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header pb-0 d-block">
                    
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table id="example" className="table table-striped dt-responsive nowrap table-hover" >
                        <thead>
                            <tr>
                                <th>Si No</th>
                                <th>Booking ID</th>
                                <th>User Name</th>
                                <th>Contact No</th>
                                <th>Total People</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>32140</td>
                                <td>Amit Kishor</td>
                                <td>+12985214756</td>
                                <td>5</td>
                                <td>17-02-2023</td>
                                <td>9:00 PM</td>
                                <td><p className="badge badge-warning">Pending</p></td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Block"><i className="fa-regular fa-circle-xmark"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>32141</td>
                                <td>Asmit Kumar</td>
                                <td>+12985214755</td>
                                <td>3</td>
                                <td>17-02-2023</td>
                                <td>7:30 PM</td>
                                <td><p className="badge badge-warning">Pending</p></td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Block"><i className="fa-regular fa-circle-xmark"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>32142</td>
                                <td>Pankaj Kumar</td>
                                <td>+12985214754</td>
                                <td>2</td>
                                <td>17-02-2023</td>
                                <td>5:15 PM</td>
                                <td><p className="badge badge-danger">Cancelled</p></td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Block"><i className="fa-regular fa-circle-xmark"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>32143</td>
                                <td>Raj Kumar</td>
                                <td>+12985214753</td>
                                <td>4</td>
                                <td>17-02-2023</td>
                                <td>8:00 PM</td>
                                <td><p className="badge badge-success">Accepted</p></td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Block"><i className="fa-regular fa-circle-xmark"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>32144</td>
                                <td>Manoj Kumar</td>
                                <td>+12985214752</td>
                                <td>3</td>
                                <td>17-02-2023</td>
                                <td>8:00 PM</td>
                                <td><p className="badge badge-success">Accepted</p></td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Block"><i className="fa-regular fa-circle-xmark"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>32145</td>
                                <td>Sunil Singh</td>
                                <td>+12985214751</td>
                                <td>2</td>
                                <td>17-02-2023</td>
                                <td>6:15 PM</td>
                                <td><p className="badge badge-danger">Cancelled</p></td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Block"><i className="fa-regular fa-circle-xmark"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>32146</td>
                                <td>Rohit kumar</td>
                                <td>+12985214750</td>
                                <td>4</td>
                                <td>17-02-2023</td>
                                <td>5:00 PM</td>
                                <td><p className="badge badge-success">Accepted</p></td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Block"><i className="fa-regular fa-circle-xmark"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>32147</td>
                                <td>Himanshu Kumar</td>
                                <td>+12985214757</td>
                                <td>3</td>
                                <td>17-02-2023</td>
                                <td>9:00 PM</td>
                                <td><p className="badge badge-success">Accepted</p></td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Block"><i className="fa-regular fa-circle-xmark"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>32148</td>
                                <td>Ankit kumar</td>
                                <td>+12985214758</td>
                                <td>5</td>
                                <td>17-02-2023</td>
                                <td>8:20 PM</td>
                                <td><p className="badge badge-success">Accepted</p></td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Block"><i className="fa-regular fa-circle-xmark"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>32149</td>
                                <td>Shanshyam kumar</td>
                                <td>+12985214759</td>
                                <td>2</td>
                                <td>17-02-2023</td>
                                <td>9:15 PM</td>
                                <td><p className="badge badge-danger">Cancelled</p></td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Block"><i className="fa-regular fa-circle-xmark"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>32150</td>
                                <td>Shyam Kumar</td>
                                <td>+12985214748</td>
                                <td>6</td>
                                <td>17-02-2023</td>
                                <td>10:00 PM</td>
                                <td><p className="badge badge-success">Accepted</p></td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Block"><i className="fa-regular fa-circle-xmark"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>32151</td>
                                <td>Ram Kumar</td>
                                <td>+12985214778</td>
                                <td>4</td>
                                <td>17-02-2023</td>
                                <td>3:30 PM</td>
                                <td><p className="badge badge-success">Accepted</p></td>
                                <td>
                                  <button className="btn btn-pill btn-outline-success btn-xs" type="button" title="View"><i className="fa-solid fa-eye"></i></button>
                                  <button className="btn btn-pill btn-outline-warning btn-xs" type="button" title="Block"><i className="fa-regular fa-circle-xmark"></i></button>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div id="right-sidebar" className="settings-panel">
            <i className="settings-close fa-regular fa-circle-xmark f-20"></i>
            
          </div>

          

        </div>
        <div className="tap-top"><i className="fa-solid fa-arrow-turn-up"></i></div>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 footer-copyright">
                <p className="mb-0">Copyright 2023 © Softa All rights reserved.</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
    </>
    )
  }
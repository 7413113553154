
import ReactPaginate from "react-paginate";
const Pagination = (props) => (
  <>
  
  <ReactPaginate
  previousLabel={"Previous"}
  nextLabel={"Next"}
  breakLabel={"..."}
  pageCount={props.pageCount}
  marginPagesDisplayed={2}
  pageRangeDisplayed={3}
  onPageChange={props.handlePageClick}
  forcePage={props.pageNumber-1}
  containerClassName={"pagination justify-content-center"}
  pageClassName={"page-item"}
  pageLinkClassName={"page-link"}
  previousClassName={"page-item"}
  previousLinkClassName={"page-link"}
  nextClassName={"page-item"}
  nextLinkClassName={"page-link"}
  breakClassName={"page-item"}
  breakLinkClassName={"page-link"}
  activeClassName={"active"}
/>

</>
);

export default Pagination;
